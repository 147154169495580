import {metadata} from "./metadata.js";
import {mutation} from "../service/mutation.js";

export function update(session, passkey, properties = {}) {
  return mutation(
    metadata,
    session,
    "update",
    properties,
    {__typename: metadata.typeName, id: passkey.id, name: passkey.name}
  );
};

import React from "react";

export function JobErrorsDisplay(props) {
  const job = props.job;
  const errors = job.errors;
  if (!errors || errors.length === 0) {
    return null;
  }
  return (
    <ol className={"errors list " + (props.className ?? "")}>
      {errors.map((e) => {
        const error = e;//JSON.parse(e);
        const key = job.id + (error.extensions?.id || error.toString());
        return (
          <li className="error" key={key}>
            {error?.extensions?.origin?.identifier && (
              <span>{error.extensions.origin.identifier}:&nbsp;</span>
            )}
            {error.message}
          </li>
        );
      })}
    </ol>
  );
}

export const metadata = Object.freeze({
  typeName: "Job",
  properties: {
    default: {
      id: true,
      version: true,

      name: true,
      type: true,
      tags: true,

      status: true,
      percentCompleted: true,
      exit: true,
      errors: true,

      createdAt: true,
      receivedAt: true,
      queuedAt: true,
      startedAt: true,
      endedAt: true,
      exitedAt: true
    }
  }
});

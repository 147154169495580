import {metadata} from "./metadata.js";
import {mutation} from "../service/mutation.js";

export function delete_(session, token, properties = {}) {
  return mutation(
    metadata,
    session,
    "delete",
    properties,
    {__typename: metadata.typeName, id: token.id}
  );
}

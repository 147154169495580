import React from "react";
import {useIntl} from "react-intl";

import {TimestampDisplay} from "../display/timestamp_display.jsx";

export function TokenExpiresDisplay(props) {
  const token = props.token;
  const intl = useIntl();

  const labelId = (token.expiresAt <= Date.now())
    ? "tokens.token.expired.label"
    : "tokens.token.expires.label";

  return (
    <div className="token-expires display">
      <span className="token-expires-label">
        {intl.formatMessage({id: labelId})}
      </span>
      <TimestampDisplay value={token.expiresAt}/>
    </div>
  );
}

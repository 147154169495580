export const metadata = Object.freeze({
  typeName: "ControlServer",
  properties: {
    default: {
      id: true,
      version: true,

      available: true,
      identifier: true
    },
    json: ["instruments.information"]
  }
});

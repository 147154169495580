import Aqumen from "@aqumen/sdk";
import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

import {AccountPasskeyCard} from "./account_passkey_card.jsx";
import {reloadCurrentUser} from "../requests/reload_current_user.js";
import {SendButton} from "../users/send_button.jsx";

export function AccountPasskeysPane(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const session = useSelector(s => s.accessControlSession);
  useEffect(() => {
    reloadCurrentUser(dispatch, session);
  }, []);

  return (
    <div className={"account-passkeys-pane pane " + (props.className ?? "")}>
      <h1 className="pane-title">{intl.formatMessage({id: "account.passkeys.title"})}</h1>
      <div className="account-send-registration">
        <SendButton user={session.user} messagePrefix="account.passkeys"/>
        <p className="account-send-registration-help">
          {intl.formatMessage({id: "account.sendRegistration.help"})}
        </p>
      </div>
      <div className="account-passkeys-list focusable-collection">
        {session.user.passkeys.map(passkey =>
          <AccountPasskeyCard key={passkey.id} passkey={passkey}/>
        )}
      </div>
    </div>
  );
}

import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

import {AccountFeaturesPanel} from "./account_features_panel.jsx";
import {AccountPreferencesPanel} from "./account_preferences_panel.jsx";
import {AccountPropertiesPanel} from "./account_properties_panel.jsx";
import {reloadCurrentUser} from "../requests/reload_current_user.js";

export function AccountPane(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const session = useSelector(s => s.accessControlSession);
  const user = useSelector(s => s.accessControlSession.user);

  const hasFeaturesPermission = session.user.roles.some(r => {
    return r.permissions.find(p => {
      return p.target === "features" && p.action === "read";
    });
  });

  useEffect(() => {
    reloadCurrentUser(dispatch, session);
  }, []);

  return (
    <div className={"account-pane pane " + (props.className ?? "")}>
      <h1 className="pane-title">
        {intl.formatMessage({id: "account.pane.title"})}
      </h1>
      <AccountPropertiesPanel user={user}/>
      <AccountPreferencesPanel user={user}/>

      {hasFeaturesPermission && (<AccountFeaturesPanel user={user}/>)}
    </div>
  );
}

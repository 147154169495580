import IsomorphicWebSocket from "isomorphic-ws";
import ReconnectingWebSocket from "reconnecting-websocket";

import {authentication} from "./authentication.js";

export function websocket(session) {
  if (!session) {
    return null;
  }

  let lastEventId = null;
  const getSocketUrl = () => {
    let url = session.url.replace(/^http/, "ws")
      + "/graphql/v2/socket/subscriptions?api-key="
      + encodeURIComponent(session.key);

    if (session?.token) {
      url += "&Authorization=" + encodeURIComponent(authentication(session));
    }
    if (lastEventId) {
      url += "&last-received-event-id=" + encodeURIComponent(lastEventId);
    }
    return url;
  };

  // RWS has different require() behavior on older Node versions
  const params = [getSocketUrl, [], {WebSocket: IsomorphicWebSocket}];
  let socket;
  try {
    socket = new ReconnectingWebSocket(...params);
  } catch (e) {
    if (/not a constructor/i.test(e.message)) {
      socket = new ReconnectingWebSocket.default(...params);
    } else {
      throw e;
    }
  }

  socket.addEventListener("message", (event) => {
    try {
      const id = JSON.parse(event.data)?.id;
      if (Number.isInteger(id)) {
        lastEventId = id;
      }
    } catch {} // eslint-disable-line no-empty
  });

  return socket;
}

import _ from "lodash";
import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {blurOnEnter} from "../utility/blur_on_enter.js";
import {ONE_DAY_MS} from "../constants.js";

function startingInputData() {
  return {description: "", expires: 365};
}

export function TokenNewCard(props) {
  const intl = useIntl();

  const [blankInputs, setBlankInputs] = useState({description: true, expires: false});
  const [invalidInputs, setInvalidInputs] = useState({description: false, expires: false});
  const [inputData, setInputData] = useState(startingInputData());
  const [working, setWorking] = useState(false);

  const session = useSelector(s => s.accessControlSession);

  const handleChange = (ev) => {
    const {name, type, min, max, minLength, value} = ev.target;
    let invalid = false;
    if (type === "number") {
      invalid = Number(value) < Number(min) || Number(value) > Number(max);
    } else if (type === "text") {
      invalid = minLength && value.length < Number(minLength);
    }

    setBlankInputs({...blankInputs, [name]: Aqumen.Utility.isBlank(value)});
    setInvalidInputs({...invalidInputs, [name]: invalid});
    setInputData(Object.assign({}, inputData, {[name]: value}));
  };

  const handleCreate = async () => {
    setWorking(true);
    try {
      const newToken = Aqumen.Utility.create("Token", session, {
        description: inputData.description,
        expiresAt: Date.now() + (ONE_DAY_MS * inputData.expires)
      });
      const created = await Aqumen.Token.create(session, newToken);
      props.onCreate(created);
      setInputData(startingInputData());
      setBlankInputs({description: true, expires: false});
      setInvalidInputs({description: false, expires: false});
      await props.reload();
    } catch (e) {
      console.error(e);
    } finally {
      setWorking(false);
    }
  };

  const disabled = working
    || Object.values(blankInputs).some(v => v)
    || Object.values(invalidInputs).some(v => v);

  let className = Object.keys(invalidInputs).reduce((r, k) => {
    return (invalidInputs[k]) ? (r + " invalid-" + _.kebabCase(k)) : r;
  }, "token-card new focusable-item");
  if (working) {
    className += " working";
  }
  return (
    <div className={className}>
      <div className="token-new-title">
        {intl.formatMessage({id: "tokens.new.title"})}
      </div>
      <label className="token-description">
        <span className="label-text">
          {intl.formatMessage({id: "tokens.new.description.label"})}
        </span>
        <input className="token-description"
                placeholder={intl.formatMessage({id: "tokens.new.description.placeholder"})}
                name="description"
                type="text"
                disabled={working}
                minLength={3}
                onChange={handleChange}
                onKeyUp={blurOnEnter}
                value={inputData.description}/>
      </label>

      <div className="token-actions">
        <button className="token-create token-action" disabled={disabled} onClick={handleCreate}>
          {intl.formatMessage({id: "tokens.new.create.button"})}
        </button>
      </div>

      <label className="token-expires">
        <span className="label-text">
          {intl.formatMessage({id: "tokens.new.expires.label"})}
        </span>
        <input className="token-expires"
                name="expires"
                type="number"
                disabled={working}
                min={1}
                max={730}
                onChange={handleChange}
                onKeyUp={blurOnEnter}
                value={inputData.expires}/>
      </label>
    </div>
  );
}

import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";
import {useDebouncedCallback} from "use-debounce";

import {blurOnEnter} from "../utility/blur_on_enter.js";
import {replace} from "../slice/replace.js";
import {usersRequest} from "../requests/users_request.js";

export function AccountPropertyAzureID(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const session = useSelector(s => s.accessControlSession);
  const hasAzureReadPermission = session.user.roles.some(r => {
    return r.permissions.find(p => {
      return p.target === "azureId" && p.action === "read";
    });
  });
  const hasAzureUpdatePermission = session.user.roles.some(r => {
    return r.permissions.find(p => {
      return p.target === "azureId" && p.action === "update";
    });
  });

  const [azureCustomerSubscriptionId, setAzureCustomerSubscriptionId] = useState(
    props.user.azureCustomerSubscriptionId || ""
  );

  const submit = async (value) => {
    const id = (Aqumen.Utility.isBlank(value)) ? null : value.trim();
    await Aqumen.User.update(session, {id: props.user.id}, {azureCustomerSubscriptionId: id});
    dispatch(replace(session, await Aqumen.User.all(session, ...usersRequest)));
  };
  const debouncedSubmit = useDebouncedCallback(submit, 1000);

  const handleChange = async (event) => {
    setAzureCustomerSubscriptionId(event.target.value);
    await debouncedSubmit(event.target.value);
  };

  const handleBlur = (event) => {
    debouncedSubmit.cancel();
    if (event.target.value === props.user.azureCustomerSubscriptionId) {
      return;
    }
    if (Aqumen.Utility.isBlank(event.target.value) && !props.user.azureCustomerSubscriptionId) {
      return;
    }
    return submit(event.target.value);
  }

  if (!hasAzureReadPermission) {
    return null;
  }

  return (
    <div className="account-property-azure-customer-subscription-id-tile">
      <label className="account-property-azure-customer-subscription-id label">
        <span className="account-property-azure-customer-subscription-id label-text">
          {intl.formatMessage({id: "account.properties.azureCustomerSubscriptionId.label"})}
        </span>
        <input type="text"
               disabled={!hasAzureUpdatePermission}
               value={azureCustomerSubscriptionId}
               onBlur={handleBlur}
               onChange={handleChange}
               onKeyUp={blurOnEnter}/>
      </label>
      <div className="account-property-help">
        {intl.formatMessage(
          {id: "account.properties.azureCustomerSubscriptionId.help"},
          {link: c => (<a target="blank" href="https://learn.microsoft.com/en-us/azure/azure-portal/get-subscription-tenant-id">{c}</a>)}
        )}
      </div>
    </div>
  );
}

export const metadata = Object.freeze({
  typeName: "Agreement",
  properties: {
    default: {
      id: true,
      version: true,

      archivedAt: true,
      identifier: true
    }
  }
});

import React, {useState} from "react";

import {JobActionsTile} from "./job_actions_tile.jsx";
import {JobControlServerDisplay} from "./job_control_server_display.jsx";
import {JobErrorsDisplay} from "./job_errors_display.jsx";
import {JobIdsTile} from "./job_ids_tile.jsx";
import {JobLastStatusDisplay} from "./job_last_status_display.jsx";
import {JobNameDisplay} from "./job_name_display.jsx";
import {JobReceivedDisplay} from "./job_received_display.jsx";
import {JobStatusDisplay} from "./job_status_display.jsx";
import {JobTimingTile} from "./job_timing_tile.jsx";
import IconCollapse from "../../assets/icons/icon_collapse.svg";
import IconExpand from "../../assets/icons/icon_expand.svg";
import {JobTimestampDisplay} from "./job_timestamp_display.jsx";

export function JobHistoryTile(props) {
  const job = props.job;
  if (_.isEmpty(job.history)) {
    return null;
  }
  const entries = job.history.map(h => {
    const history = JSON.parse(h);
    return {
      timestamp: history[0],
      formattedTimestamp: new Date(history[0]).toISOString().match(/^.*\dT(\d.*)Z$/)[1] + " UTC",
      action: _.upperCase(history[1])
    };
  }).sort((a, b) => a.timestamp - b.timestamp);

  return (
    <div className="job-history-tile tile">
      {entries.map(h => (
        <div key={job.id + h.timestamp.toString() + h.action} className="job-history-entry">
          <span className="history-timestamp">{h.formattedTimestamp}</span>
          <span className="history-action">{h.action}</span>
        </div>
      ))}
    </div>
  );
}

import Aqumen from "@aqumen/sdk";

import {update} from "./slice/update.js";

export function websocket(dispatch, session) {
  const socket = Aqumen.Service.websocket(session);
  socket.addEventListener("message", (event) => {
    const data = JSON.parse(event.data);
    console.debug("Websocket data", data);
    if (data.data) {
      dispatch(update(session, data.data[data.type]));
    }
  });
  return socket;
}

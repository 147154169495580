import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import QubitArrangment from "../../assets/icons/qubit_arrangement.svg";

import {CouplersDisplay} from "./couplers_display.jsx";
import {QubitsDisplay} from "./qubits_display.jsx";
import {flatEnvironmentSelector} from "../utility/flat_environment_selector.js";
import {selectUserPermissions} from "../utility/select_user_permissions.js";

// import {selectFakeCalibrations} from "../../fake_data/select_fake_calibrations.js";

export function CalibrationCard(props) {
  const intl = useIntl();

  // const selectCalibrations = selectFakeCalibrations;
  const selectCalibrations = flatEnvironmentSelector("calibrations");

  const filterCalibrations = createSelector(selectCalibrations, (all) => {
    return all.filter(c => c.controlServer.id === props.controlServer?.id);
  });
  const latestCalibration = createSelector(filterCalibrations, (filtered) => {
    return filtered.reduce(
      (r, c) => (c.createdAt > r?.createdAt) ? c : r, filtered[0]
    );
  });
  const createOrdered = (prop) => {
    return createSelector(latestCalibration, (calibration) => {
      return _.orderBy(calibration?.[prop], "identifier", "asc");
    });
  };

  const selectCalibrationPermission = createSelector(
    selectUserPermissions,
    userPermissions => userPermissions.find(p =>
      p.target === "calibrations" && p.action === "read"
    )
  );

  const orderCouplers = createOrdered("couplerCalibrations");
  const orderQubits = createOrdered("qubitCalibrations");

  const calibration = useSelector(latestCalibration);
  const couplers = useSelector(orderCouplers);
  const qubits = useSelector(orderQubits);

  const hasCalibrationPermission = useSelector(selectCalibrationPermission);

  let className = "calibration-card";
  if (props.controlServer?.available) {
    className += " available";
  }
  if (!props.controlServer.available
      || Boolean(props.controlServer?.lockedAt)
      || Boolean(props.controlServer?.commandStatus !== "normal")) {
    className += " unusable";
  }

  return (
    <div className={className}>
        {props.controlServer && (
          <div className="qubits-display">
            <QubitArrangment className="qubit-arrangement" />
            <div className="identifier">{props.controlServer.identifier}</div>
          </div>
        )}
        {calibration && (
          <div className="calibration-display">
            <QubitsDisplay {...{calibration, qubits}}/>
            <CouplersDisplay {...{calibration, couplers}}/>
          </div>
        )}
        {!calibration && hasCalibrationPermission && (
          <div className="calibration-display empty">
            {intl.formatMessage(
              {id: "systems.calibration.empty"},
              {
                controlServer: props.controlServer.identifier,
                identifier: i => <span className="identifier">{i}</span>
              }
            )}
          </div>
        )}
    </div>
  );
}

import _ from "lodash";
import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
  name: "Permission",
  initialState: {},
  reducers: {
    "replace": (_state, action) => {
      return _.keyBy(action.payload, "id");
    },
    "update": (state, action) => {
      state[action.payload.id] = action.payload;
    }
  },
  extraReducers: (builder) => builder
    .addCase("sessions/deauthenticate", () => ({}))
});

export const {replace, update} = slice.actions;
export const {reducer} = slice;

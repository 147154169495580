import _curryN from "lodash/fp/curryN.js";

import {metadata} from "./metadata.js";
import {mutation} from "../service/mutation.js";
import Utility from "../utility/index.js";

export const reject = _curryN(3, (session, original, message, properties) => {
  return mutation(
    metadata,
    session,
    "reject",
    properties,
    Utility.update(original, {message})
  );
});

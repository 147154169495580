export const metadata = Object.freeze({
  typeName: "User",
  properties: {
    default: {
      id: true,
      version: true,

      archivedAt: true,
      identifier: true,

      fullName: true,
      email: true
    }
  }
});

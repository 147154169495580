import _ from "lodash";
import React, {useEffect, useState} from "react";

import {formatDuration} from "../format/format_duration.js";

export function DisplayCountdown(props) {
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    let iv;
    if (Date.now() >= props.value) {
      clearInterval(iv);
      setNow(props.value);
    } else {
      iv = setInterval(() => setNow(Date.now()), 100);
      return () => {
        clearInterval(iv);
      };
    }
  }, [now]);

  return (
    <span className="display-countdown clock">
      {formatDuration(props.value - now)}
    </span>
  );
};

import _curryN from "lodash/fp/curryN.js";

import Utility from "../utility/index.js";

const debug = Utility.debug("aqumen:sdk:job:continuation");

export const continuation = _curryN(3, (session, action, spec, add) => {
  debug("from %j", spec);
  return Promise.resolve(Object.assign({}, spec, {
    action,
    id: Utility.generateID(session),
    upload: {},
    pipe: {id: spec.id}
  }, add));
});

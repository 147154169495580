import {createSelector} from "@reduxjs/toolkit";

export const selectNextUnacceptedAgreement = createSelector(
  s => s.agreements,
  s => s.accessControlSession?.user?.acceptedAgreements,
  (all, accepted) => {
    if (!all) {
      return null;
    }
    console.debug("ACCEPTED", accepted);
    return Object.values(all).find(
      aa => !aa.archivedAt && (!accepted || !accepted.find(ua => ua.agreement.id === aa.id))
    );
  }
);

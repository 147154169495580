import React from "react";

export const centerTruncate = (original) => {
  return (
    <span>
      {original.slice(0, 4)}
      <span className="center-truncation">…</span>
      {original.slice(-4)}
    </span>
  );
};

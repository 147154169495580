import _curryN from "lodash/fp/curryN.js";

import {metadata} from "./metadata.js";
import {mutation} from "../service/mutation.js";

export const unlock = _curryN(2, (session, controlServer, properties = {}) => {
  return mutation(
    metadata, session, "unlock", {...properties, lockedAt: true, lockedBy: {id: true}}, controlServer
  );
});

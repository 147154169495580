import Aqumen from "@aqumen/sdk";

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";

import {setPreferences} from "../slice/preferences_slice";

export function JobsPreferenceToggle(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const preferenceKey = "preferences.qwa.jobs." + props.preference;

  const session = useSelector(s => s.accessControlSession);
  const user = useSelector(s => s.accessControlSession.user);
  const preferences = useSelector(s => s.preferences)

  const handleChange = () => {
    const changed = Object.assign(
      {}, preferences, {[preferenceKey]: !preferences[preferenceKey]}
    );
    dispatch(setPreferences(changed));
    return Aqumen.User.update(
      session, {id: user.id}, {preferences: JSON.stringify(changed)}
    );
  };

  const checked = Boolean(preferences?.[preferenceKey]);

  return (
    <label className="jobs-preference toggle label">
      <span className="jobs-preference toggle label-text">
        {intl.formatMessage({id: "jobs." + preferenceKey})}
      </span>
      <input className="jobs-preference toggle checkbox" type="checkbox" checked={checked} onChange={handleChange}/>
    </label>
  );
}

import crockford from "base32-crockford-browser";

export const errorID = (error) => {
  const source = `${Date.now()}:${JSON.stringify(error)}`;
  let hash = 0;
  for (let i = 0; i < source.length; i++) {
      const c = source.charCodeAt(i);
      hash = ((hash << 5) - hash) + c;
      hash &= hash;
  }
  const u8a = new Uint8Array([
    (hash & 0xff000000) >> 24,
    (hash & 0x00ff0000) >> 16,
    (hash & 0x0000ff00) >> 8,
    (hash & 0x000000ff)
  ]);
  return crockford.encode(u8a).toUpperCase().match(/.{1,3}/g).join("-");
};

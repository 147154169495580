import {errorID} from "./error_id.js";

export class AqumenError extends Error {
  constructor(data) {
    super(data.message);

    this.id = data.id || data.extensions?.id || errorID(data);
    this.origin = data.origin || data.extensions?.origin?.identifier;
    this.code = data.code || data.extensions?.code;
    this.detail = data.detail || data.extensions?.detail;
  }

  toString() {
    return [this.id, this.origin, this.code, this.message].join(" ");
  }
}

import Aqumen from "@aqumen/sdk";

import {setCurrentUser} from "../slice/access_control_session_slice.js";
import {update as updateUser} from "../slice/users_slice.js";
import {usersRequest} from "./users_request.js";

export async function reloadCurrentUser(dispatch, session) {
  const latest = await Aqumen.User.me(session, usersRequest[0]);
  dispatch(updateUser(latest));
  dispatch(setCurrentUser([latest]));
};

import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {flatSelector} from "../utility/flat_selector.js";
import {NewUserTile} from "./new_user_tile.jsx";
import {reloadOne} from "../requests/reload_one.js";
import {UserCard} from "./user_card.jsx";
import {usersRequest} from "../requests/users_request";

export function UsersPane() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [includeArchived, setIncludeArchived] = useState(false);
  const [showNewUser, setShowNewUser] = useState(false);

  const selectFilteredUsers = createSelector(
    flatSelector("users"), us => us.filter(u => includeArchived || !u.archivedAt)
  );
  const selectManageableUsers = createSelector(
    selectFilteredUsers, us => us.filter(u => u.identifier !== "azure_quantum")
  );
  const selectOrderedUsers = createSelector(
    selectManageableUsers, u => _.orderBy(u, "fullName", "asc")
  );
  const users = useSelector(selectOrderedUsers);
  const sessions = useSelector(s => s.environmentSessions);

  const filter = (includeArchived) ? {} : {users: {archived: false}};
  const reload = () => reloadOne("User", usersRequest, dispatch, sessions, filter);
  useEffect(() => {reload();}, [includeArchived]);

  const handleToggleArchived = () => setIncludeArchived(!includeArchived);

  const handleToggleShowNewUser = () => {
    if (showNewUser) {
      setShowNewUser(false);
      return;
    }

    setShowNewUser(true);

  };

  return (
    <div className="users-pane pane focusable-collection">
      <div className="users-title pane-title">
        {intl.formatMessage({id: "users.title"})}
      </div>
      <div className="users-options">
        <div className="users-option">
          <label className="users-option-include-archived">
            <input className="users-option-include-archived"
                   type="checkbox"
                   onChange={handleToggleArchived}
                   checked={includeArchived}/>
            <span className="label-text">
              {intl.formatMessage({id: "users.options.includeArchived.checkbox.label"})}
            </span>
          </label>
        </div>
        <div className="users-option">
          <label className="users-option-show-new-user">
            <input className="users-option-show-new-user"
                  type="checkbox"
                  onChange={handleToggleShowNewUser}
                  checked={showNewUser}/>
            <span className="label-text">
              {intl.formatMessage({id: "users.options.showNewUser.checkbox.label"})}
            </span>
          </label>
        </div>
      </div>
      <div className="users-list">
        {showNewUser && (
          <NewUserTile reload={reload}/>
        )}
        {users.map(user => (
          <UserCard key={user.id} user={user} reload={reload}/>
        ))}
      </div>
    </div>
  );
}

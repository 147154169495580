import _ from "lodash";
import Aqumen from "@aqumen/sdk";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";
import {useDebouncedCallback} from "use-debounce";

import {blurOnEnter} from "../utility/blur_on_enter.js";
import {mapEntityIdsToSet} from "../utility/map_entity_ids_to_set.js";
import {createChangeSet} from "./create_change_set.js";
import {PermissionCard} from "./permission_card.jsx";
import {permissionsRequest} from "../requests/permissions_request.js";
import {reloadOne} from "../requests/reload_one.js";
import {RoleCard} from "./role_card.jsx";
import {rolesRequest} from "../requests/roles_request.js";
import {selectPermissions} from "./select_permissions.js";
import {selectRoles} from "./select_roles.js";
import {selectUsers} from "./select_users.js";
import {setUserInterface} from "../slice/user_interface_slice.js";
import {updateSelection} from "./update_selection.js";
import {UserCard} from "./user_card.jsx";
import {usersRequest} from "../requests/users_request.js";

export function AccessControlPane() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const session = useSelector(s => s.accessControlSession);
  const selectStoredFilters = createSelector(s => s, s => s.userInterface?.["accessControl.filters"] ?? {});
  const storedFilters = useSelector(selectStoredFilters);

  const users = useSelector(selectUsers);
  const roles = useSelector(selectRoles);
  const permissions = useSelector(selectPermissions(intl));

  const [mode, setMode] = useState("read");
  const [selecting, setSelecting] = useState("none");

  const [filters, setFilters] = useState(storedFilters);

  const [userIdsSelected, setUserIdsSelected] = useState(new Set());
  const [roleIdsSelected, setRoleIdsSelected] = useState(new Set());
  const [permissionIdsSelected, setPermissionIdsSelected] = useState(new Set());

  const [userIdsToAdd, setUserIdsToAdd] = useState(new Set());
  const [userIdsToRemove, setUserIdsToRemove] = useState(new Set());
  const [roleIdsToAdd, setRoleIdsToAdd] = useState(new Set());
  const [roleIdsToRemove, setRoleIdsToRemove] = useState(new Set());
  const [permissionIdsToAdd, setPermissionIdsToAdd] = useState(new Set());
  const [permissionIdsToRemove, setPermissionIdsToRemove] = useState(new Set());

  const selectedUsers = users.filter(u => userIdsSelected.has(u.id));
  const selectedRoles = roles.filter(r => roleIdsSelected.has(r.id));
  const selectedPermissions = permissions.filter(p => permissionIdsSelected.has(p.id));

  let linkedAnyUsers = [];
  let linkedAllUsers = [];
  let linkedAnyRoles = [];
  let linkedAllRoles = [];
  let linkedAnyPermissions = [];
  let linkedAllPermissions = [];

  if (mode === "users" || (mode === "read" && selecting === "users")) {
    linkedAnyRoles = roles.filter(r => {
      return selectedUsers.some(su => su.roles.some(ur => ur.id === r.id));
    });
    linkedAllRoles = roles.filter(r => {
      return (selectedUsers.length > 0
        && selectedUsers.every(su => su.roles.some(ur => ur.id === r.id))
      );
    });

    linkedAnyPermissions = permissions.filter(p => {
      return linkedAnyRoles.some(sr => sr.permissions.some(srp => srp.id === p.id))
    });
    linkedAllPermissions = permissions.filter(p => {
      return linkedAllRoles.length > 0
        && linkedAllRoles.every(sr => sr.permissions.some(srp => srp.id === p.id));
    });
  }

  if (mode === "roles" || (mode === "read" && selecting === "roles")) {
    linkedAnyUsers = users.filter(u => {
      return u.roles.some(r => roleIdsSelected.has(r.id))
    });
    linkedAllUsers = users.filter(u => {
      return roleIdsSelected.size > 0
        && selectedRoles.every(sr => u.roles.some(ur => ur.id === sr.id))
    });

    linkedAnyPermissions = permissions.filter(p => {
      return selectedRoles.some(sr => sr.permissions.some(srp => srp.id === p.id))
    });
    linkedAllPermissions = permissions.filter(p => {
      return selectedRoles.length > 0
        && selectedRoles.every(sr => sr.permissions.some(srp => srp.id === p.id));
    });
  }

  if (mode === "read" && selecting === "permissions") {
    linkedAnyRoles = roles.filter(r => {
      return selectedPermissions.some(sp => r.permissions.some(rp => rp.id === sp.id));
    });
    linkedAllRoles = roles.filter(r => {
      return selectedPermissions.length > 0
        && selectedPermissions.every(sp => r.permissions.some(rp => rp.id === sp.id));
    });

    linkedAnyUsers = users.filter(u => {
      return u.roles.some(r => linkedAnyRoles.find(lar => lar.id === r.id))
    });
    linkedAllUsers = users.filter(u => {
      return linkedAllRoles.length > 0
        && linkedAllRoles.every(lar => u.roles.some(ur => ur.id === lar.id))
    });
  }

  const linkedAnyUserIds = mapEntityIdsToSet(linkedAnyUsers);
  const linkedAllUserIds = mapEntityIdsToSet(linkedAllUsers);
  const linkedAnyRoleIds = mapEntityIdsToSet(linkedAnyRoles);
  const linkedAllRoleIds = mapEntityIdsToSet(linkedAllRoles);
  const linkedAnyPermissionIds = mapEntityIdsToSet(linkedAnyPermissions);
  const linkedAllPermissionIds = mapEntityIdsToSet(linkedAllPermissions);

  const compareLinkedChanged = (selected, linked, add, remove, key) => (a, b) => {
    const s = selected.has(a.id);
    const bs = selected.has(b.id);
    if (s || bs) {
      return (s === bs) ? a[key].localeCompare(b[key]) : s ? -1 : 1;
    }

    const aa = add.has(a.id) || remove.has(a.id);
    const ba = add.has(b.id) || remove.has(b.id);
    if (aa || ba) {
      return (aa === ba) ? a[key].localeCompare(b[key]) : aa ? -1 : 1;
    }

    const al = linked.has(a.id);
    const bl = linked.has(b.id);
    return (al === bl) ? a[key].localeCompare(b[key]) : al ? -1 : 1;
  };

  const orderedUsers = users.sort(compareLinkedChanged(
    userIdsSelected, linkedAnyUserIds, userIdsToAdd, userIdsToRemove, "fullName"
  ));
  const orderedRoles = roles.sort(compareLinkedChanged(
    roleIdsSelected, linkedAnyRoleIds, roleIdsToAdd, roleIdsToRemove, "identifier",
  ));
  const orderedPermissions = permissions.sort(compareLinkedChanged(
    permissionIdsSelected, linkedAnyPermissionIds, permissionIdsToAdd, permissionIdsToRemove, "short"
  ));

  const reload = () => Promise.all([
    reloadOne("User", usersRequest, dispatch, [session]),
    reloadOne("Role", rolesRequest, dispatch, [session]),
    reloadOne("Permission", permissionsRequest, dispatch, [session])
  ]);
  useEffect(() => {reload();}, []);

  const clearSelections = () => {
    setUserIdsSelected(new Set());
    setRoleIdsSelected(new Set());
    setPermissionIdsSelected(new Set());
    clearChanges();
  };

  const clearChanges = () => {
    setUserIdsToAdd(new Set());
    setUserIdsToRemove(new Set());
    setRoleIdsToAdd(new Set());
    setRoleIdsToRemove(new Set());
    setPermissionIdsToAdd(new Set());
    setPermissionIdsToRemove(new Set());
  };

  const handleChangeMode = (ev) => {
    setMode(ev.target.value);
    clearChanges();
    if (ev.target.value === "users") {
      setRoleIdsSelected(new Set());
      setPermissionIdsSelected(new Set());
    }
    if (ev.target.value === "roles") {
      setUserIdsSelected(new Set());
      setPermissionIdsSelected(new Set());
    };
  };

  const submitFilters = (updated) => {
    dispatch(setUserInterface({"accessControl.filters": updated}));
  }
  const debouncedSubmitFilters = useDebouncedCallback(submitFilters, 1000);
  const handleFilterChange = (filterKey) => (ev) => {
    const updated = Object.assign({}, filters, {[filterKey]: ev.target.value});
    setFilters(updated);
    debouncedSubmitFilters(updated);
  };

  const handleClickUser = (clickedId) => () => {
    setSelecting("users");

    if (mode === "roles") {
      if (roleIdsSelected.size <= 0) {
        return;
      }
      const [add, remove] = createChangeSet(
        linkedAnyUsers, linkedAllUsers, userIdsToAdd, userIdsToRemove, clickedId
      );
      setUserIdsToAdd(add);
      setUserIdsToRemove(remove);
    } else {
      setUserIdsSelected(updateSelection(userIdsSelected, clickedId));
      setRoleIdsSelected(new Set());
      setPermissionIdsSelected(new Set());
    }
  };

  const handleClickRole = (clickedId) => () => {
    setSelecting("roles");

    if (mode === "users") {
      if (userIdsSelected.size <= 0) {
        return;
      }
      const [add, remove] = createChangeSet(
        linkedAnyRoles, linkedAllRoles, roleIdsToAdd, roleIdsToRemove, clickedId
      );
      setRoleIdsToAdd(add);
      setRoleIdsToRemove(remove);
    } else {
      setUserIdsSelected(new Set());
      setRoleIdsSelected(updateSelection(roleIdsSelected, clickedId));
      setPermissionIdsSelected(new Set());
    }
  };

  const handleClickPermission = (clickedId) => () => {
    if (mode === "roles") {
      const [add, remove] = createChangeSet(
        linkedAnyPermissions, linkedAllPermissions,
        permissionIdsToAdd, permissionIdsToRemove,
        clickedId
      );
      setPermissionIdsToAdd(add);
      setPermissionIdsToRemove(remove);
    } else if (mode === "read" || mode === "roles") {
      setSelecting("permissions");

      setUserIdsSelected(new Set());
      setRoleIdsSelected(new Set());
      setPermissionIdsSelected(updateSelection(permissionIdsSelected, clickedId));
    }
  };

  const handleClickSave = async () => {
    const requests = [];
    if (mode === "users") {
      users.filter(u => userIdsSelected.has(u.id)).forEach(u => {
        const newRoles = _.difference(
          _.union(u.roles.map(r => r.id), Array.from(roleIdsToAdd)),
          Array.from(roleIdsToRemove)
        ).map(id => ({id}));
        console.debug("update", {newRoles, roleIdsToAdd, roleIdsToRemove});
        requests.push(Aqumen.User.update(session, {id: u.id}, {roles: newRoles}));
      });
    }

    if (mode === "roles") {
      roles.filter(r => roleIdsSelected.has(r.id)).forEach(r => {
        const newPermissions = _.difference(
          _.union(r.permissions.map(p => p.id), Array.from(permissionIdsToAdd)),
          Array.from(permissionIdsToRemove)
        ).map(id => ({id}));
        requests.push(Aqumen.Role.update(session, {id: r.id}, {permissions: newPermissions}));
      });
    }

    await Promise.all(requests);
    await reload();
    clearChanges();
  };

  const handleClickCancel = clearSelections;

  let noChanges = true;
  if (mode === "users") {
    noChanges = roleIdsToAdd.size === 0
      && roleIdsToRemove.size === 0;
  }
  if (mode === "roles") {
    noChanges = userIdsToAdd.size === 0
      && userIdsToRemove.size === 0
      && permissionIdsToAdd.size === 0
      && permissionIdsToRemove.size === 0;
  }

  let className = "access-control-pane pane focusable-collection";
  className += ` mode-${mode}`;
  className += ` selecting-${selecting}`;
  className +=  (noChanges) ? " no-changes" : " changes-made";

  return (
    <div className={className}>
      <div className="access-control-title pane-title">
        {intl.formatMessage({id: "accessControl.title"})}
      </div>
      <div className="access-control-mode">
        <div className="access-control-action review">
          <label>
            <input type="radio" checked={mode === "read"} value="read" onChange={handleChangeMode}/>
            <span className="label-text">
              Review (click items to see connections)
            </span>
          </label>
        </div>
        <div className="access-control-action change-users-roles">
          <label>
            <input type="radio" checked={mode === "users"} value="users" onChange={handleChangeMode}/>
            <span className="label-text">
              Change Users' Roles
            </span>
          </label>
        </div>
        <div className="access-control-action change-roles-permissions">
          <label>
            <input type="radio" checked={mode === "roles"} value="roles" onChange={handleChangeMode}/>
            <span className="label-text">
              Change Roles' Permissions
            </span>
          </label>
        </div>
      </div>
      <div className="access-control-help">
        Roles group permissions into common, reusable building blocks. Use those
        building blocks to assemble permission sets for specific users. For example,
        there might be a role strictly for hardware-related activity and another
        for job management, i.e., managing others' jobs; most users at QCI
        would likely have both of those roles.
      </div>
      <div className="access-control-actions">
        <button className="access-control-action save-button" onClick={handleClickSave} disabled={noChanges}>
          Save Changes
        </button>
        <button className="access-control-action cancel-button" onClick={handleClickCancel} disabled={noChanges}>
          Discard Changes
        </button>
      </div>
      <div className="access-control-lists">
        <div className="access-control-filter users">
          <input type="search" onKeyUp={blurOnEnter} onChange={handleFilterChange("users")} value={filters.users ?? ""}/>
        </div>
        <div className="access-control-list users">
          {orderedUsers.map(user => (
            <UserCard key={user.id}
              user={user}
              onClick={handleClickUser(user.id)}
              inAnySelected={linkedAnyUserIds.has(user.id)}
              inAllSelected={linkedAllUserIds.has(user.id)}
              selected={userIdsSelected.has(user.id)}
              editing={mode === "users" && userIdsSelected.has(user.id)}
              adding={roleIdsSelected.size > 0 && userIdsToAdd.has(user.id)}
              removing={roleIdsSelected.size > 0 && userIdsToRemove.has(user.id)}
            />
          ))}
        </div>
        <div className="access-control-filter roles">
          <input type="search" onKeyUp={blurOnEnter} onChange={handleFilterChange("roles")} value={filters.roles ?? ""}/>
        </div>
        <div className="access-control-list roles">
          {orderedRoles.map(role => (
            <RoleCard role={role}
              key={role.id}
              onClick={handleClickRole(role.id)}
              inAnySelected={linkedAnyRoleIds.has(role.id)}
              inAllSelected={linkedAllRoleIds.has(role.id)}
              selected={roleIdsSelected.has(role.id)}
              adding={userIdsSelected.size > 0 && roleIdsToAdd.has(role.id)}
              removing={userIdsSelected.size > 0 && roleIdsToRemove.has(role.id)}
            />
          ))}
        </div>
        <div className="access-control-filter permissions">
          <input type="search" onKeyUp={blurOnEnter} onChange={handleFilterChange("permissions")} value={filters.permissions ?? ""}/>
        </div>
        <div className="access-control-list permissions">
          {orderedPermissions.map(permission => (
            <PermissionCard permission={permission}
              key={permission.id}
              onClick={handleClickPermission(permission.id)}
              inAnySelected={linkedAnyPermissionIds.has(permission.id)}
              inAllSelected={linkedAllPermissionIds.has(permission.id)}
              selected={permissionIdsSelected.has(permission.id)}
              adding={roleIdsSelected.size > 0 && permissionIdsToAdd.has(permission.id)}
              removing={roleIdsSelected.size > 0 && permissionIdsToRemove.has(permission.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

import _curry from "lodash/fp/curry.js";
import _isArray from "lodash/fp/isArray.js";
import _has from "lodash/fp/has.js";
import _set from "lodash/fp/set.js";

const JSON_TYPES = {
  "ControlServerInstrument": ["information"],
  "Environment": ["runParameters"],
  "Job": ["errors"]
};

export const processJSON = _curry((func, entity) => {
  const properties = JSON_TYPES[entity.__typename];
  let clone = entity;
  (properties ?? []).forEach((k) => {
    if (_has(k, entity)) {
      if (_isArray(entity[k])) {
        clone = _set(k, entity[k].map(func), clone);
      } else {
        clone = _set(k, func(entity[k]), clone);
      }
    }
  });
  return clone;
});

import Aqumen from "@aqumen/sdk";
import _ from "lodash";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useDebouncedCallback} from "use-debounce";

import {blurOnEnter} from "../utility/blur_on_enter.js";
import {CONFIRM_INSTRUCTIONS_TIMEOUT} from "../constants.js";
import {formatTimestamp} from "../format/format_timestamp.js";
import {reloadCurrentUser} from "../requests/reload_current_user.js";
import {RegisterPasskeyTile} from "../authentication/register_passkey_tile.jsx";

const PASSKEY_METADATA = {
  typeName: "Passkey",
  properties: {default: {id: true}}
}

export function AccountPasskeyCard(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const session = useSelector(s => s.accessControlSession);

  const [name, setName] = useState(props.passkey.name);
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [confirmingSync, setConfirmingSync] = useState(false);

  const submit = async (value) => {
    await Aqumen.Service.mutation(
      PASSKEY_METADATA,
      session,
      "update",
      {},
      Aqumen.Utility.update(_.pick(props.passkey, ["id", "name", "version"]), {name: value})
    );
    await reloadCurrentUser(dispatch, session);
  };
  const debouncedSubmit = useDebouncedCallback(submit, 1000);

  const handleChange = async (event) => {
    setName(event.target.value);
    await debouncedSubmit(event.target.value);
  };

  const handleBlur = (event) => {
    debouncedSubmit.cancel();
    if (event.target.value !== props.passkey.name) {
      return submit(event.target.value);
    }
  };

  const handleDelete = async () => {
    if (confirmingDelete) {
      await Aqumen.Service.mutation(
        PASSKEY_METADATA,
        session,
        "delete",
        {},
        {id: props.passkey.id}
      );
      await reloadCurrentUser(dispatch, session);
    } else {
      setConfirmingDelete(true);
      setTimeout(() => setConfirmingDelete(false), CONFIRM_INSTRUCTIONS_TIMEOUT);
    }
  };

  const handleSyncStart = () => setConfirmingSync(true);
  const handleSyncCancel = () => setConfirmingSync(false);

  let deleteClassName = "account-passkey-delete";
  let buttonMessageId = "account.passkeys.tile.delete.button";

  let className = "account-passkey-card focusable-item";

  if (confirmingDelete) {
    className += " confirming";
    deleteClassName += " confirming";
    buttonMessageId = "account.passkeys.tile.deleteConfirm.button";
  }

  return (
    <div className={className}>
      <label className="account-passkey-name-label">
        <span className="account-passkey-name-label-text label-text">
          {intl.formatMessage({id: "account.passkeys.tile.name.label"})}
        </span>
        <input className="account-passkey-name"
               type="text"
               value={name}
               onBlur={handleBlur}
               onChange={handleChange}
               onKeyUp={blurOnEnter}/>
      </label>

      <div className="account-passkey-actions">
        <button className="account-passkey-sync-start" onClick={handleSyncStart}>
          {intl.formatMessage({id: "account.passkeys.tile.sync.start.button"})}
        </button>

        <button className={deleteClassName} onClick={handleDelete}>
          {intl.formatMessage({id: buttonMessageId})}
        </button>
      </div>

      <div className="account-passkey-description">
        {intl.formatMessage(
          {id: "account.passkeys.tile.description"},
          {createdAt: formatTimestamp(props.passkey.createdAt)}
        )}
      </div>

      {confirmingDelete && (
        <div className="account-passkey-delete-confirmation">
          {intl.formatMessage({id: "account.passkeys.tile.deleteConfirm.help"})}
        </div>
      )}

      {confirmingSync && (
        <div className="account-passkey-sync-confirmation-backdrop" onClick={handleSyncCancel}>
          <div className="account-passkey-sync-confirmation">
            <div className="account-passkey-sync-help">
              {intl.formatMessage(
                {id: "account.passkeys.tile.sync.help"},
                {name: (<span className="replacement-name">{name}</span>), p: c => (<p>{c}</p>)}
              )}
            </div>
            <RegisterPasskeyTile
              messagePrefix="account.passkeys.tile.sync"
              replacePasskey={props.passkey}
              doneCallback={handleSyncCancel}
            />
            <button className="account-passkey-sync-cancel" onClick={handleSyncCancel}>
              {intl.formatMessage({id: "account.passkeys.tile.sync.cancel.button"})}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

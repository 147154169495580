import _get from "lodash/fp/get.js";

import {AqumenError} from "../error/aqumen_error.js";
import {origin} from "../error/origin.js";

export class ServiceHTTPError extends AqumenError {
  constructor(response, text) {
    let parsed = null;
    try {
      parsed = JSON.parse(text);
    } catch (e) {} // eslint-disable-line no-empty
    super({
      code: response.code || (response.status || "").toString(),
      detail: (parsed) ? parsed.detail : text,
      errors: [response],
      id: _get("extensions.id", parsed),
      message: response.message || response.statusText,
      name: "HttpError",
      origin: origin({origin: {type: "service"}})
    });
  }
}

import _ from "lodash";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {findAndIdentify} from "./find_and_identify.js";
import {flatEnvironmentSelector} from "../utility/flat_environment_selector.js";
import {formatTimestamp} from "../format/format_timestamp.js";

export function DashboardReservationStatusTile() {
  const intl = useIntl();

  const selectControlServerIdentifiers = createSelector(
    flatEnvironmentSelector("controlServers"),
    cs => cs.reduce((a, c) => {
      a[c.id] = c.identifier;
      return a;
    }, {})
  );
  const selectApprovedReservations = createSelector(
    flatEnvironmentSelector("reservations"),
    s => s.accessControlSession,
    (rs, se) => rs.filter(r => !r.archivedAt && !r.rejectedAt && r.approvedAt && r.recipient?.id === se.user.id)
  );
  const selectOrderedReservations = createSelector(
    selectApprovedReservations, u => _.orderBy(u,
      ["activateAt", "duration", "createdAt"],
      ["desc", "desc", "desc"]
    )
  );
  const selectActiveReservation = createSelector(
    selectOrderedReservations,
    selectControlServerIdentifiers,
    findAndIdentify(r => r.active)
  );
  const selectUpcomingReservation = createSelector(
    selectOrderedReservations,
    selectControlServerIdentifiers,
    findAndIdentify(r => r.activateAt > Date.now())
  );
  const active = useSelector(selectActiveReservation);
  const upcoming = useSelector(selectUpcomingReservation);

  let className = "tile dashboard-reservation-status-tile";
  let display = {};
  let messageId;
  if (active) {
    className += " active";
    messageId = "dashboard.reservation.active";
    display = {
      identifier: active.controlServer.identifier,
      endsAt: formatTimestamp(active.activateAt + duration, {forceTime: true})
    };
  } else if (upcoming) {
    className += " upcoming";
    messageId = "dashboard.reservation.upcoming";
    display = {
      identifier: upcoming.controlServer.identifier,
      activateAt: formatTimestamp(upcoming.activateAt)
    };
  } else {
    className += " empty";
    messageId = "dashboard.reservation.empty";
  }

  return (
    <div className={className}>
      {intl.formatMessage({id: messageId}, display)}
    </div>
  );
}

import React, {useState} from "react";

export function RoleCard(props) {
  const [focused, setFocused] = useState(false);
  const handleClick = () => {
    setFocused(!focused);
    props.onClick();
  };

  let className = "role-card card";
  if (focused) {
    className += " focused";
  }
  if (props.selected) {
    className += " selected";
  }
  if (props.inAnySelected) {
    className += " in-any-selected";
  }
  if (props.inAllSelected) {
    className += " in-all-selected";
  }
  if (props.adding) {
    className += " adding";
  }
  if (props.removing) {
    className += " removing";
  }
  return (
    <div className={className} onClick={handleClick}>
      <div className="adding-decoration">+ ADDING</div>
      <div className="removing-decoration">- REMOVING</div>
      <div className="role-identifier identifier">{props.role.identifier}</div>
      <div className="role-description description">{props.role.description}</div>
    </div>
  );
}

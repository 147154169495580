export const metadata = Object.freeze({
  typeName: "Simulator",
  properties: {
    default: {
      id: true,
      version: true,

      available: true,
      identifier: true,
      type: true
    }
  }
});

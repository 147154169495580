import _merge from "lodash/fp/merge.js";
import jtgq from "json-to-graphql-query";

import {deserialize} from "../service/deserialize.js";
import {metadata} from "./metadata.js";
import {postGraphQL} from "../service/post_graphql.js";

export function me(session, properties = {}) {
  return postGraphQL(session, jtgq.jsonToGraphQLQuery({
    query: {
      me: {
        ..._merge(metadata.properties.default, properties)
      }
    }
  }), {})
  .then((p) => deserialize(p.data.me));
}

import _isArray from "lodash/fp/isArray.js";

import {AqumenError} from "./aqumen_error.js";
import {origin} from "./origin.js";
import {processErrors} from "./process_errors.js";

export class ServiceAPIError extends AqumenError {
  constructor(payload) {
    let code;
    let errors;
    let message;
    if (_isArray(payload.errors)) {
      errors = payload.errors;
      message = (payload.errors.length > 1)
        ? "multiple errors"
        : payload.errors[0].message;
    } else {
      const actual = payload.error || payload.errors || payload;
      code = actual.code || actual.status;
      errors = [actual];
      message = actual.detail
        || actual.message
        || (payload.status === 401) ? "authentication required" : null
        || (payload.status === 403) ? "access denied" : null;
    }
    errors = processErrors(errors.map((e) => {
      return Object.assign({name: "ApiError", origin: {type: "service"}}, e);
    }));
    super({
      code,
      errors,
      message,
      name: "ApiError",
      origin: origin({origin: {type: "service"}})
    });
  }
}

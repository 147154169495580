export const jobsRequest = [{
  artifacts: {id: true, type: true, url: true},
  controlServer: {id: true},
  createdBy: {id: true},
  errorCount: true,
  environmentName: true,
  history: true,
  pipeJob: {id: true},
  simulator: {id: true, type: true},
  source: true,
  sourceId: true,
  sourceExtendedId: true,
  tags: true
}, {jobs: {limit: 1000}}, {}];

import _curryN from "lodash/fp/curryN.js";

import {websocket} from "../service/websocket.js";

const WS_OPEN = 1;
export const command = _curryN(2, (payload, session, socket) => {
  const tempSocket = (socket) ? null : websocket(session);
  return new Promise((resolve) => {
    const ws = socket || tempSocket;
    const message = JSON.stringify(payload);
    if (ws.readyState === WS_OPEN) {
      ws.send(message);
      return resolve();
    }
    const sendSignal = () => {
      ws.send(message);
      ws.removeEventListener("open", sendSignal);
      resolve();
    };
    ws.addEventListener("open", sendSignal);
  }).finally(() => {
    if (tempSocket) {
      tempSocket.close();
    }
  });
});

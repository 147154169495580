import {authentication} from "./authentication.js";
import {SDKError} from "../error/sdk_error.js";

const USER_AGENT = "Aqumen SDK/21.0.0";

export function headers(session) {
  if (!session) {
    throw new SDKError("Missing session");
  }
  if (!session.key) {
    throw new SDKError("Missing session.key");
  }
  const h = {
    "Accept": "application/json",
    "API-Token": session.key,
    "Content-Type": "application/json"
  };

  if (session.token) {
    h.Authorization = authentication(session);
  }

  if (typeof navigator === "undefined") {
    h["User-Agent"] = USER_AGENT;
  }
  return h;
}

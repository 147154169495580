import {IntlProvider} from "react-intl";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {AgreementView} from "./agreements/agreement_view.jsx";
import {AppErrorBoundary} from "./app_error_boundary.jsx";
import {ConnectionsContext} from "./context/connections_context.js";
import {NoEnvironmentAccessView} from "./authentication/no_environment_access_view.jsx";
import {RegisterPasskeyView} from "./authentication/register_passkey_view.jsx";
import {RootNavigation} from "./root_navigation.jsx";
import {selectNextUnacceptedAgreement} from "./slice/select_next_unaccepted_agreement.js";
import {SignInView} from "./authentication/sign_in_view.jsx";
import {websocket} from "./websocket.js";

import enUS from "./locales/en_us.json";

export function App() {
  const dispatch = useDispatch();

  const selectSessions = createSelector(s => s.environmentSessions, s => Object.values(s));

  const sessions = useSelector(selectSessions);
  const unacceptedAgreement = useSelector(selectNextUnacceptedAgreement);
  const accessControlSession = useSelector(s => s.accessControlSession);
  const connected = useSelector(s => s.userInterface.connected);
  const overrideColorSchemePreference = useSelector(
    s => s.preferences?.["preferences.qwa.overrideColorScheme"]
  );
  const useColorSchemeOne = useSelector(
    s => s.preferences?.["features.qwa.theme.colorsOne"]
  );

  const [connections, setConnections] = useState({});

  useEffect(() => {
    if (_.isEmpty(connections) && !_.isEmpty(sessions)) {
      let c = {};
      sessions.forEach(async s => c[s.url] = await websocket(dispatch, s));
      setConnections(c);
    }
  }, []);

  const requireAuthentication = () => {
    const urlParams = new URLSearchParams(document.location.search);
    const registrationToken = urlParams.get("registration-token");
    if (registrationToken) {
      return <RegisterPasskeyView registrationToken={registrationToken}/>;
    }
    if (!accessControlSession?.user) {
      return <SignInView/>;
    }
    if (unacceptedAgreement) {
      return <AgreementView/>;
    }
    if (connected && Object.keys(sessions).length <= 0) {

      return <NoEnvironmentAccessView/>;
    }
    return <RootNavigation/>;
  }

  const root = document.getElementsByTagName("html")[0];

  const overrideColorScheme = overrideColorSchemePreference
    || localStorage.getItem("preferences.qwa.overrideColorScheme");

  if (overrideColorScheme === "dark") {
    root.classList.remove("override-system-scheme-light");
    root.classList.add("override-system-scheme-dark");
  } else if (overrideColorScheme === "light") {
    root.classList.remove("override-system-scheme-dark");
    root.classList.add("override-system-scheme-light");
  } else {
    root.classList.remove("override-system-scheme-light");
    root.classList.remove("override-system-scheme-dark");
  }

  const colorScheme = (useColorSchemeOne) ? "colors-one" : "default";
  root.setAttribute("data-theme", colorScheme);

  const UA = navigator.userAgent;
  if (/Chrome\/\w+/.test(UA) && !/Edg.*\/\w+/.test(UA)) {
    root.classList.add("chrome");
  }
  if (/Win/.test(UA)) {
    root.classList.add("windows");
  }

  return (
    <div className="app">
      <AppErrorBoundary>
        <IntlProvider locale={"en-US"} messages={enUS}>
          <ConnectionsContext.Provider value={{connections, setConnections}}>
            {requireAuthentication()}
          </ConnectionsContext.Provider>
        </IntlProvider>
      </AppErrorBoundary>
    </div>
  );
}

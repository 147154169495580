import React from "react";

import {JobLastStatusDisplay} from "../jobs/job_last_status_display.jsx";
import {JobNameDisplay} from "../jobs/job_name_display.jsx";
import {JobTimingTile} from "../jobs/job_timing_tile.jsx";
import {ONE_DAY_MS} from "../constants.js";

export function DashboardJobCard(props) {
  const job = props.job;
  const jobSource = job.source ?? "aqumen";

  let className = `dashboard-job-card ${job.type} status-${job.status} ${jobSource}`;
  if (open) {
    className += " card-open";
  }
  if (job.percentCompleted > 0 && job.percentCompleted < 100) {
    className += " has-percent-complete";
  }
  if (job.exit) {
    className += " exit-" + job.exit;
  }
  if (job.signal) {
    className += " signal-" + job.signal;
  }
  if (job.exitedAt) {
    className += " exited";
  } else if (job.startedAt) {
    className += " executing";
  }
  if (job.errorCount > 0 || job.exit === "error") {
    className += " error";
  }
  if (job.exitedAt && Date.now() - job.exitedAt > 2 * ONE_DAY_MS) {
    className += " old";
  }

  return (
    <div className={className}>
      <div className="dashboard-job-column job-name">
        <JobNameDisplay job={job}/>
      </div>
      <div className="dashboard-job-column job-timing">
        <JobTimingTile job={job}/>
      </div>
      <div className="dashboard-job-column job-status">
        <JobLastStatusDisplay job={job}/>
      </div>
    </div>
  );
}

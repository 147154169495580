import React from "react";
import {useIntl} from "react-intl";
import {TimestampDisplay} from "../display/timestamp_display.jsx";

import {jobStatusMessage} from "./job_status_message.js";


export function JobQueueDisplay(props) {
  const job = props.job;
  const intl = useIntl();

  let className = "job-queue-display";
  let message;
  if (job.exit === "error") {
    message = intl.formatMessage(
      {id: "jobs.job.error"}
    )
    className += " error";

  } else if (job.queuePosition > 0) {
    message = intl.formatMessage(
      {id: "jobs.job.queuePosition"}, {position: job.queuePosition + 1}
    )
    className += " queued";
  } else if (!job.exitedAt) {
    message = intl.formatMessage(
      {id: "jobs.job.waiting." + job.type}, {system: job.controlServer?.identifier}
    )
    className += " waiting";
  }
  return (
    <span className={className}>
      {message}
    </span>
  );
}

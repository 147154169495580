import _ from "lodash";
import Aqumen from "@aqumen/sdk";

import {replace} from "../slice/replace.js";

const ACCESS_CONTROL_ENTITIES = [
  "Permission", "Role", "User"
]

export function reloadOne(entityName, request, dispatch, sessions, filter = {}) {
  const properties = request[0];
  const merged = _.merge(structuredClone(request[1]), filter);
  const onceOnly = ACCESS_CONTROL_ENTITIES.includes(entityName);

  for (let key of Object.keys(sessions)) {
    (async () => dispatch(replace(
      sessions[key], await Aqumen[entityName].all(sessions[key], properties, merged)
    )))();
    if (onceOnly) {
      break;
    }
  }
}

import {createSelector} from "@reduxjs/toolkit";

import {flatSelector} from "../utility/flat_selector.js";
import {regexFromUser} from "../utility/regex_from_user.js";
import {selectStoredFilters} from "./select_stored_filters.js";

const selectUnarchivedUsers = createSelector(
  flatSelector("users"), us => us.filter(u => !u.archivedAt)
);

const selectManageableUsers = createSelector(
  selectUnarchivedUsers, us => us.filter(u => u.identifier !== "azure_quantum")
);

const selectFilteredUsers = createSelector(
  selectManageableUsers, selectStoredFilters, (mu, sf) => {
    const filterExp = regexFromUser(sf.users);
    return mu.filter(u => {
      return filterExp.test(u.fullName)
        || filterExp.test(u.email)
        || filterExp.test(u.identifier)
        || filterExp.test(u.id);
    });
  }
);

export const selectUsers = createSelector(
  selectFilteredUsers, us => _.orderBy(us, "fullName", "asc")
);

import Instrument from "./instrument/index.js";

import {all} from "./all.js";
import {create} from "./create.js";
import {lock} from "./lock.js";
import {restart} from "./restart.js";
import {terminateAll} from "./terminate_all.js";
import {unlock} from "./unlock.js";
import {update} from "./update.js";


export default {Instrument, all, create, lock, restart, terminateAll, unlock, update};

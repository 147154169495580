import React, {useState} from "react";
import {useIntl} from "react-intl";

import {ArchiveButton} from "./archive_button.jsx";
import {DisplayId} from "../display/display_id.jsx";
import {SendButton} from "./send_button.jsx";
import {UserEmailTile} from "./user_email_tile.jsx";
import {UserFullNameTile} from "./user_full_name_tile.jsx";

export function UserCard(props) {
  const intl = useIntl();
  const [confirmingRegistration, setConfirmingRegistration] = useState(false);
  const [confirmingArchive, setConfirmingArchive] = useState(false);

  let className = "user-card card focusable-item";
  let messagePrefix = "users.user";
  if (props.user.archivedAt) {
    className += " archived";
    messagePrefix += ".archived";
  }
  if (confirmingArchive) {
    className += " confirming confirming-archive";
  }
  if (confirmingRegistration) {
    className += " confirming confirming-registration";
  }
  return (
    <div className={className}>
      <div className="user-card-inputs">
        <UserFullNameTile user={props.user} messagePrefix={messagePrefix} reload={props.reload}/>
        <UserEmailTile user={props.user} messagePrefix={messagePrefix} reload={props.reload}/>
      </div>

      <div className="user-card-meta">
        <div className="user-card-ids">
          <div className="user-card-aqumen-id">
            <span className="data-label">
              {intl.formatMessage({id: `users.user.id.aqumen.label`})}
            </span>
            <span className="data-value">
              <DisplayId id={props.user.id} disabled={confirmingArchive}/>
            </span>
          </div>
          {false && props.user.azureCustomerSubscriptionId && (
            <div className="user-card-azure-id">
              <span className="data-label">
                {intl.formatMessage({id: `users.user.id.azure.label`})}
              </span>
              <span className="data-value">
                <DisplayId id={props.user.azureCustomerSubscriptionId} disabled={confirmingArchive}/>
              </span>
            </div>
          )}
        </div>
        <div className="user-card-actions">
          <SendButton user={props.user}
                      confirm={true}
                      disabled={confirmingArchive}
                      messagePrefix="users.user"
                      setConfirming={setConfirmingRegistration}/>
          <ArchiveButton user={props.user}
                         disabled={confirmingRegistration}
                         reload={props.reload}
                         setConfirming={setConfirmingArchive}/>
        </div>
      </div>
    </div>
  );
}

import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
  name: "UserInterface",
  initialState: {navigationMenuOpen: true},
  reducers: {
    "setUserInterface": (state, {payload}) => {
      return Object.assign({}, state, payload);
    }
  }
});

export const {setUserInterface} = slice.actions;
export const {reducer} = slice;

export const metadata = Object.freeze({
  typeName: "Metric",
  properties: {
    default: {
      id: true,
      version: true,

      compiler: {id: true},
      controlServer: {id: true},
      organization: {id: true},
      simulator: {id: true},
      user: {id: true},

      identifier: true,
      value: true
    }
  }
});

export const metadata = Object.freeze({
  typeName: "JobQueue",
  properties: {
    default: {
      id: true,
      version: true,

      queued: {id: true, version: true, createdAt: true, type: true, name: true},
      type: true
    }
  }
});

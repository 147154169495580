import _ from "lodash";
import React from "react";
import {useIntl} from "react-intl";

export function JobNameDisplay(props) {
  const job = props.job;
  const intl = useIntl();

  const jobSource = job.source ?? "aqumen";
  const jobName = intl.formatMessage(
    {id: `jobs.job.${jobSource}.name`}, {name: job.name}
  );

  return (
    <h2 className="job-name-display display">
      <span className="job-name-text">{jobName}</span>
    </h2>
  );
}

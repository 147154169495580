import Aqumen from "@aqumen/sdk";
import React from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

import {setPreferences} from "../slice/preferences_slice";


export function AccountSettingRadio(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const settings = useSelector(s => s.preferences);
  const session = useSelector(s => s.accessControlSession);
  const user = useSelector(s => s.accessControlSession.user);

  const value = settings?.[props.setting] || props.values[0];

  const handleChange = async (ev) => {
    const changed = Object.assign(
      {}, settings, {[props.setting]: ev.target.value}
    );
    dispatch(setPreferences(changed));
    if (props.local) {
      localStorage.setItem("preferences.qwa.overrideColorScheme", ev.target.value);
    }
    await Aqumen.User.update(
      session, {id: user.id}, {preferences: JSON.stringify(changed)}
    );
  };

  let className = `account-setting radio ${_.kebabCase(props.setting)}`;
  if (value === null) {
    className += " default";
  }
  return (
    <div className={className}>
      <fieldset>
        <legend className="label-text">
          {intl.formatMessage({id: "account.settings." + props.setting})}
        </legend>

        {props.values.map((v) => {
          const sv = (v) ?  _.kebabCase(v.toString()) : "null";
          const checked = v === value;

          return (
            <label className={`account-setting label ${sv}`} key={sv}>
              <input type="radio" name={sv} checked={checked} value={v} onChange={handleChange}/>
              <span className={"account-setting label-text"}>
                {intl.formatMessage({id: `account.settings.${props.setting}.${sv}`})}
              </span>
            </label>
          );
        })}
      </fieldset>
    </div>
  );
};

import React from "react";
import {useIntl} from "react-intl";

import IconDownload from "../../assets/icons/icon_download.svg";
import IconNewWindow from "../../assets/icons/icon_new_window.svg";

export function DashboardQuickLinksTile() {
  const intl = useIntl();

  return (
    <div className="tile dashboard-quick-links-tile dashboard-section">
      <h2 className="dashboard-quick-links-title dashboard-section title">
        {intl.formatMessage({id: "dashboard.quickLinks.title"})}
      </h2>
      <ul className="dashboard-quick-links-list">
        <li className="dashboard-quick-links-item">
          <a href="#" download>
            <IconDownload aria-label={intl.formatMessage({id: "dashboard.quickLinks.downloadManual.aria"})}/>
            {intl.formatMessage({id: "dashboard.quickLinks.downloadManual.label"})}
          </a>
        </li>
        <li className="dashboard-quick-links-item">
          <a href="https://quantumcircuits.com/dual-resonator-qubits-breakthrough/" target="_blank">
            <IconNewWindow aria-label={intl.formatMessage({id: "dashboard.quickLinks.dualResonatorQubits.aria"})}/>
            {intl.formatMessage({id: "dashboard.quickLinks.dualResonatorQubits.label"})}
          </a>
        </li>
        <li className="dashboard-quick-links-item">
          <IconNewWindow aria-label={intl.formatMessage({id: "dashboard.quickLinks.companyWebsite.aria"})}/>
          <a href="https://quantumcircuits.com" target="_blank">
            {intl.formatMessage({id: "dashboard.quickLinks.companyWebsite.label"})}
          </a>
        </li>
      </ul>
    </div>
  );
}

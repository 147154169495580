import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {CONFIRM_DESTRUCTIVE_TIMEOUT} from "../constants.js";

export function SendButton(props) {
  const intl = useIntl();

  const session = useSelector(s => s.accessControlSession);
  const [confirming, setConfirming] = useState(false);
  const [sending, setSending] = useState(false);

  const handleClick = async () => {
    if (props.confirm && !confirming) {
      setConfirming(true);
      props.setConfirming?.(true);
      setTimeout(() => {
        setConfirming(false);
        props.setConfirming?.(false);
      }, CONFIRM_DESTRUCTIVE_TIMEOUT);
      return;
    }
    props.setConfirming?.(false);
    setConfirming(false);
    setSending(true);
    try {
      await fetch(session.url + "/api/v2/webauthn/registration/send", {
        method: "POST",
        credentials: "include",
        headers: {
          "API-Key": session.key,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({id: props.user.id})
      });
    } finally {
      setTimeout(() => setSending(false), 1000);
    }
  };

  const disabled = props.disabled || sending || Boolean(props.user.archivedAt);

  let buttonClassName = "user-registration-send-button";
  let messageState = "";
  if (confirming) {
    buttonClassName += " confirming";
    messageState = ".confirming";
  }
  if (sending) {
    buttonClassName += " sending";
    messageState = ".sending";
  }
  return (
    <div className="user-registration-send">
      <button className={buttonClassName} onClick={handleClick} disabled={disabled}>
        {intl.formatMessage({id: `${props.messagePrefix}.registration.send${messageState}.button`})}
      </button>
    </div>
  );
}

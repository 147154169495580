import _ from "lodash";

export function replace(session, payload) {
  if (_.isEmpty(payload)) {
    return {payload: "noop", type: "noop"};
  }
  return {
    type: `${payload[0].__typename}/replace`,
    session,
    payload: payload.map(p => Object.assign({}, p, {session}))
  };
}

import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {flatSelector} from "../utility/flat_selector.js";
import {permissionsRequest} from "../requests/permissions_request.js";
import {reloadOne} from "../requests/reload_one.js";
import {RoleCard} from "./role_card.jsx";
import {RoleCardNew} from "./role_card_new.jsx";
import {rolesRequest} from "../requests/roles_request.js";
import {visibleRole} from "./visible_role.js";

export function RolesPane() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [includeArchived, setIncludeArchived] = useState(false);
  const [showNewRole, setShowNewRole] = useState(false);

  const selectVisibleRoles = createSelector(
    flatSelector("roles"), ar => ar.filter(visibleRole)
  );
  const selectFilteredRoles = createSelector(
    selectVisibleRoles, ar => ar.filter(u => includeArchived || !u.archivedAt)
  );
  const selectPermissionedRoles = createSelector(
    selectFilteredRoles,
    flatSelector("permissions"),
    (fr, ap) => fr.map(r => Object.assign({}, r, {
      permissions: r.permissions.map(rp => ap.find(p => p.id === rp.id))
    }))
  );
  const selectOrderedRoles = createSelector(
    selectPermissionedRoles, pr => _.orderBy(pr, "identifier", "asc")
  );
  const roles = useSelector(selectOrderedRoles);
  const sessions = useSelector(s => s.environmentSessions);

  const filter = (includeArchived) ? {} : {roles: {archived: false}};
  const reload = () => {
    reloadOne("Permission", permissionsRequest, dispatch, sessions);
    reloadOne("Role", rolesRequest, dispatch, sessions, filter);
  };
  useEffect(() => {reload();}, [includeArchived]);

  const handleToggleArchived = () => setIncludeArchived(!includeArchived);

  const handleToggleShowNewRole = () => {
    if (showNewRole) {
      setShowNewRole(false);
      return;
    }

    setShowNewRole(true);
  };

  return (
    <div className="roles-pane pane focusable-collection">
      <div className="roles-title pane-title">
        {intl.formatMessage({id: "roles.title"})}
      </div>
      <div className="roles-options">
        <div className="roles-option">
          <label className="roles-option-include-archived">
            <input className="roles-option-include-archived"
                   type="checkbox"
                   onChange={handleToggleArchived}
                   checked={includeArchived}/>
            <span className="label-text">
              {intl.formatMessage({id: "roles.options.includeArchived.checkbox.label"})}
            </span>
          </label>
        </div>
        <div className="roles-option">
          <label className="roles-option-show-new-role">
            <input className="roles-option-show-new-role"
                  type="checkbox"
                  onChange={handleToggleShowNewRole}
                  checked={showNewRole}/>
            <span className="label-text">
              {intl.formatMessage({id: "roles.options.showNewRole.checkbox.label"})}
            </span>
          </label>
        </div>
      </div>
      <div className="roles-list">
        {showNewRole && (
          <RoleCardNew reload={reload}/>
        )}
        {roles.map(role => (
          <RoleCard key={role.id} role={role} roles={roles} reload={reload}/>
        ))}
      </div>
    </div>
  );
}

import _ from "lodash";
import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {flatEnvironmentSelector} from "../utility/flat_environment_selector.js";
import {blurOnEnter} from "../utility/blur_on_enter.js";
import {CONFIRM_DESTRUCTIVE_TIMEOUT, ONE_HOUR_MS} from "../constants.js";

function emptyReservation() {
  return {description: ""};
}

const DESCRIPTION_MIN_WORDS = 0;

export function AccountReservationCardNewComplex(props) {
  const intl = useIntl();

  const [blanks, setBlanks] = useState({description: true, activateAt: true, duration: true, system: true});
  const [confirming, setConfirming] = useState(false);
  const [invalids, setInvalids] = useState({description: false, activateAt: false, duration: false, system: false});
  const [reservation, setReservation] = useState(emptyReservation());
  const [working, setWorking] = useState(false);

  const session = useSelector(s => s.accessControlSession);
  const selectAllControlServers = flatEnvironmentSelector("controlServers");
  const selectUnarchivedControlServers = createSelector(selectAllControlServers, as => {
    return as.filter(s => !s.archivedAt);
  });
  const controlServers = useSelector(selectUnarchivedControlServers);

  const handleChangeActivateAt = (ev) => {
    if (Aqumen.Utility.isBlank(ev.target.value)) {
      setBlanks({...blanks, activateAt: true});
      setInvalids({...invalids, activateAt: false});
      setReservation(Object.assign({}, reservation, {activateAt: null}));
      return;
    }

    try {
      const activateAt = new Date(ev.target.value).getTime();
      setReservation(Object.assign({}, reservation, {activateAt}));
      setBlanks({...blanks, activateAt: false});
      setInvalids({...invalids, activateAt: false});
    } catch {
      setBlanks({...blanks, activateAt: false});
      setInvalids({...invalids, activateAt: true});
    }
  };

  const handleChangeDescription = (ev) => {
    setReservation(Object.assign({}, reservation, {description: ev.target.value}));
    if (ev.target.value.length > 0
        && (ev.target.value.match(/\S+/g)?.length ?? 0) < DESCRIPTION_MIN_WORDS) {
      setBlanks({...blanks, description: false});
      setInvalids({...invalids, description: true});
    } else if (Aqumen.Utility.isBlank(ev.target.value)) {
      setBlanks({...blanks, description: true});
      setInvalids({...invalids, description: false});
    } else {
      setBlanks({...blanks, description: false});
      setInvalids({...invalids, description: false});
    }
  };

  const handleChangeDuration= (ev) => {
    if (Aqumen.Utility.isBlank(ev.target.value)) {
      setBlanks({...blanks, duration: true});
      setInvalids({...invalids, duration: false});
      setReservation(Object.assign({}, reservation, {duration: null}));
      return;
    }

    try {
      const duration = parseInt(ev.target.value) * ONE_HOUR_MS;
      setReservation(Object.assign({}, reservation, {duration}));
      setBlanks({...blanks, duration: false});
      setInvalids({...invalids, duration: false});
    } catch {
      setBlanks({...blanks, duration: false});
      setInvalids({...invalids, duration: true});
    }
  };

  const handleChangeSystem= (ev) => {
    if (Aqumen.Utility.isBlank(ev.target.value)) {
      setBlanks({...blanks, system: true});
      setInvalids({...invalids, system: false});
      setReservation(Object.assign({}, reservation, {controlServer: {id: null}}));
      return;
    }

    setReservation(Object.assign({}, reservation, {controlServer: {id: ev.target.value}}));
    setBlanks({...blanks, system: false});
    setInvalids({...invalids, system: false});
  };

  const handleCreate = async () => {
    if (!confirming) {
      setConfirming(true);
      setTimeout(() => setConfirming(false), CONFIRM_DESTRUCTIVE_TIMEOUT);
      return;
    }
    setConfirming(false);
    setWorking(true);
    try {
      const newReservation = Aqumen.Utility.create(
        "Reservation", session, {...reservation, recipient: {id: session.user.id}}
      );
      await Aqumen.Reservation.create(session, newReservation);
      setReservation(emptyReservation());
      setBlanks({description: true});
      setConfirming(false);
    } catch (e) {
      setConfirming(true);
      setTimeout(() => setConfirming(false), CONFIRM_DESTRUCTIVE_TIMEOUT);
    } finally {
      setWorking(false);
    }
  };

  const disabled = working
    || Object.values(blanks).some(v => v)
    || Object.values(invalids).some(v => v);

  let className = Object.keys(invalids).reduce((r, k) => {
    return (invalids[k]) ? (r + " invalid-" + _.kebabCase(k)) : r;
  }, "account-reservation-card new complex focusable-item");
  if (working) {
    className += " working";
  }
  if (confirming) {
    className += " confirming";
  }
  return (
    <div className={className}>
      <h2 className="account-reservation-new-title">
        {intl.formatMessage({id: "account.reservations.new.complex.title"})}
      </h2>
      <div className="account-reservation-card-inputs">
        <label className="account-reservation-description">
          <span className="label-text">
            {intl.formatMessage({id: "account.reservations.new.complex.description.label"})}
          </span>
          <textarea className="account-reservation-description"
                    placeholder={intl.formatMessage({id: "account.reservations.new.complex.description.placeholder"})}
                    type="text"
                    disabled={working}
                    onChange={handleChangeDescription}
                    onKeyUp={blurOnEnter}
                    value={reservation.description}/>
        </label>
        <div className="account-reservation-description-help">
          {intl.formatMessage({id: "account.reservations.new.complex.description.help"})}
        </div>
        <div className="account-reservation-extra">
          <div className="account-reservation-system">
            <label className="account-reservation-activate-at">
              <span className="label-text">
                {intl.formatMessage({id: "account.reservations.new.complex.system.label"})}
              </span>
              <select className="account-reservation-system"
                      onChange={handleChangeSystem}>
                <option value=""></option>
                {controlServers.map(s => (
                  <option key={s.id} value={s.id}>{s.identifier}</option>
                ))}
              </select>
            </label>
          </div>
          <div className="account-reservation-activate-at">
            <label className="account-reservation-activate-at">
              <span className="label-text">
                {intl.formatMessage({id: "account.reservations.new.complex.activateAt.label"})}
              </span>
              <input className="account-reservation-activate-at"
                    type="datetime-local"
                    onChange={handleChangeActivateAt}/>
            </label>
          </div>
          <div className="account-reservation-duration">
            <label className="account-reservation-duration">
              <span className="label-text">
                {intl.formatMessage({id: "account.reservations.new.complex.duration.label"})}
              </span>
              <input className="account-reservation-duration"
                    type="number"
                    step="1"
                    min="1"
                    max="1000"
                    pattern="\d*"
                    onChange={handleChangeDuration}/>
            </label>
          </div>
          <div className="account-reservation-card-action">
            <button className="account-reservation-create" disabled={disabled} onClick={handleCreate}>
              {!confirming && intl.formatMessage({id: "account.reservations.new.complex.create.button"})}
              {confirming && intl.formatMessage({id: "account.reservations.new.complex.create.confirm.button"})}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import _get from "lodash/fp/get.js";

export function origin(error) {
  const origin = error.origin || _get("extensions.origin", error) || {type: "sdk"};
  const identifier =  (origin.identifier) ? ` '${origin.identifier}'` : "";
  switch (origin.type) {
    case "compiler": return `QCI Compiler${identifier}`;
    case "controlServerProxy": return `Aqumen JCS Proxy${identifier}`;
    case "controlServer": return `QCI JCS${identifier}`;
    case "sdk": return "Aqumen SDK";
    case "service": return `Aqumen Service${identifier}`;
    case "simulator": return `Simulator${identifier}`;
    case "":
    case null:
    case undefined: return "Unspecified Origin";
    default: return `Unrecognized Origin "${origin.type}"`;
  }
}

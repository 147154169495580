import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";
import {useIntl} from "react-intl";

import {CalibrationCard} from "./calibration_card.jsx";
import {CalibrationErrorBoundary} from "./calibration_error_boundary.jsx";
import {SystemsList} from "./systems_list.jsx";
import {reloadAll} from "../requests/reload_all.js";
import {flatEnvironmentSelector} from "../utility/flat_environment_selector.js";

// import {selectFakeControlServers} from "../../fake_data/select_fake_control_servers.js";


export function SystemsPane(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const sessions = useSelector((s) => s.environmentSessions);
  const session = useSelector(s => s.accessControlSession);

  // const selectControlServers = selectFakeControlServers;
  const selectControlServers = flatEnvironmentSelector("controlServers");

  const selectFilter = createSelector(s => s, s => s.filters?.systemDetail);
  const selectControlServer = createSelector(selectControlServers, selectFilter, (all, f) => {
    return all.find((cs) => {
      return cs.id === f?.system?.id
      && cs?.session?.url === f?.system?.session?.url;
    });
  });

  // Temporary perm check to hide qubit arrangement from non-QCI per Andrei.
  const hasCalibrationPermission = session.user.roles.some(r => {
    return r.permissions.find(p => {
      return p.target === "calibrations" && p.action === "read";
    });
  });

  const controlServer = useSelector(selectControlServer);

  useEffect(() => reloadAll(dispatch, sessions), []);

  let className = "systems-pane pane";
  if (controlServer) {
    className += " system-detail-open";
  }
  return (
    <div className={className}>
      <SystemsList/>
      {hasCalibrationPermission && controlServer && (
        <CalibrationErrorBoundary controlServer={controlServer}>
          <CalibrationCard controlServer={controlServer}/>
        </CalibrationErrorBoundary>
      )}
      {hasCalibrationPermission && !controlServer && (
        <div className="calibration-card no-control-server">
          {intl.formatMessage({id: "systems.calibration.noControlServer"})}
        </div>
      )}
    </div>
  );
}

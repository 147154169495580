import Utility from "../utility/index.js";

const debug = Utility.debug("aqumen:sdk:job:specification");

export function specification(session, action, base = {}) {
  debug("%s from %O", action, base);
  const spec = Utility.create("Job", session, Object.assign({action}, base));
  debug("created %O", spec);
  return Promise.resolve(spec);
}

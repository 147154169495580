import React, {useState} from "react";
import {useIntl} from "react-intl";

import {JobActionsTile} from "./job_actions_tile.jsx";
import {JobErrorsDisplay} from "./job_errors_display.jsx";
import {JobIdsTile} from "./job_ids_tile.jsx";
import {JobLastStatusDisplay} from "./job_last_status_display.jsx";
import {JobNameDisplay} from "./job_name_display.jsx";
import {JobTimingTile} from "./job_timing_tile.jsx";
import IconCollapse from "../../assets/icons/icon_collapse.svg";
import IconExpand from "../../assets/icons/icon_expand.svg";
import {JobHistoryTile} from "./job_history_tile.jsx";
import {ONE_DAY_MS} from "../constants.js";
import {DisplayId} from "../display/display_id.jsx";

export function JobCard(props) {
  const intl = useIntl();
  const job = props.job;
  const [open, setOpen] = useState(false);

  const jobSource = job.source ?? "aqumen";

  const handleSummaryClick = (ev) => {
    (props.handleSummaryClick) ? props.handleSummaryClick(ev) : setOpen(!open);
  };
  const handleExpandClick = (ev) => {
    ev.stopPropagation();
    setOpen(!open);
  };
  let errorId = null;
  const firstErrorJSON = job.errors?.[0];
  if (firstErrorJSON) {
    // errorId = JSON.parse(firstErrorJSON)?.extensions?.id;
    errorId = firstErrorJSON?.extensions?.id;
  }

  let className = `job-card ${job.type} status-${job.status} ${jobSource}`;
  if (open) {
    className += " card-open";
  }
  if (job.percentCompleted > 0 && job.percentCompleted < 100) {
    className += " has-percent-complete";
  }
  if (job.exit) {
    className += " exit-" + job.exit;
  }
  if (job.signal) {
    className += " signal-" + job.signal;
  }
  if (job.exitedAt) {
    className += " exited";
  } else if (job.startedAt) {
    className += " executing";
  }
  if (job.errorCount > 0 || job.exit === "error") {
    className += " error";
  }
  if (job.exitedAt && Date.now() - job.exitedAt > 2 * ONE_DAY_MS) {
    className += " old";
  }

  let jobTarget = "";
  if (job.type === "compile") {
    jobTarget = job.environmentName
      ?? intl.formatMessage({id: "jobs.job.type.compile.environment.unknown"});
  } else if (job.type === "simulate") {
    jobTarget = job.environmentName
      ?? job.simulator?.identifier
      ?? intl.formatMessage({id: "jobs.job.type.simulate.simulator.unknown"});
  } else if (job.type === "run") {
    jobTarget = job.controlServer?.identifier
      ?? intl.formatMessage({id: "jobs.job.type.run.controlServer.unknown"});
  }

  return (
    <div className={className}>
      <div className="job-summary" onClick={handleSummaryClick}>
        <div className="summary-column">
          {!open && (
            <IconExpand onClick={handleExpandClick}/>
          )}
          {open && (
            <IconCollapse onClick={handleExpandClick}/>
          )}
          {props.selecting && (
            <input className="job-selected-toggle"
              checked={props.selected}
              disabled={job.exitedAt}
              type="checkbox"
              onChange={props.handleToggle}
              onClick={(ev) => ev.stopPropagation()}/>
          )}
          <JobNameDisplay job={job}/>
        </div>
        {props.showCreatorInSummary && (
          <div className="job-creator">
            {job.createdBy?.fullName ?? "Unknown User"}
          </div>
        )}
        <div className="summary-column">
          <div className="job-target">
            {jobTarget}
          </div>
        </div>
        <div className="summary-column">
          <JobTimingTile job={job}/>
        </div>
        <div className="summary-column">
          <JobLastStatusDisplay job={job}/>
        </div>
      </div>

      {open && (
        <div className="job-details">
          <div className="job-details-column main-action">
            <JobIdsTile job={job}/>
            {props.showCreatorInDetails && (
              <div className="job-creator">

                {job.createdBy && (
                  <span>{job.createdBy.fullName} &lt;{job.createdBy.email}&gt;</span>
                )}
                {!job.createdBy && (
                  <span>Unknown User</span>
                )}
              </div>
            )}
            {!_.isEmpty(job.tags) && (
              <div className="job-tags">{intl.formatList(job.tags)}</div>
            )}
            {/* <JobTagsDisplay job={job}/> */}
            {Boolean(errorId) && (
              <div className="job-error-id">
                <span className="data-label">
                  {intl.formatMessage({id: "jobs.job.id.error.label"})}
                </span>
                <span className="data-value">
                  <DisplayId id={errorId} truncate={false}/>
                </span>
              </div>
            )}
            <JobErrorsDisplay job={job}/>
            <JobActionsTile job={job}/>
          </div>
          <div className="job-details-column history">
            <JobHistoryTile job={job}/>
          </div>
          {/* <div className="job-detail-timing">
            <JobTimestampDisplay value={job.createdAt} messageId="jobs.job.detail.createdAt.label" messageEmptyId="jobs.job.detail.createdAt.empty"/>
            <JobTimestampDisplay value={job.receivedAt} messageId="jobs.job.detail.receivedAt.label" messageEmptyId="jobs.job.detail.receivedAt.empty"/>
            <JobTimestampDisplay value={job.queuedAt} messageId="jobs.job.detail.queuedAt.label" messageEmptyId="jobs.job.detail.queuedAt.empty"/>
            <JobTimestampDisplay value={job.startedAt} messageId="jobs.job.detail.startedAt.label" messageEmptyId="jobs.job.detail.startedAt.empty"/>
            <JobTimestampDisplay value={job.signaledAt} messageId="jobs.job.detail.signaledAt.label" messageEmptyId="jobs.job.detail.signaledAt.empty"/>
            <JobTimestampDisplay value={job.endedAt} messageId="jobs.job.detail.endedAt.label" messageEmptyId="jobs.job.detail.endedAt.empty"/>
            <JobTimestampDisplay value={job.exitedAt} messageId="jobs.job.detail.exitedAt.label" messageEmptyId="jobs.job.detail.exitedAt.empty"/>
          </div> */}
        </div>
      )}
    </div>
  );
}

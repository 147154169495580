import _curry from "lodash/fp/curry.js";

import {fetch} from "../service/fetch.js";

export const create = _curry((url, key, identifier, credential) => {
  const payload = JSON.stringify({identifier, credential});
  return fetch({url, key}, "POST", "/sessions", payload)
  .then((response) => {
    return response.json().then((j) => Object.assign({}, j.data, {url, key}));
  });
});

import _ from "lodash";
import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {flatSelector} from "../utility/flat_selector.js";
import {reloadOne} from "../requests/reload_one.js";
import {PermissionCard} from "./permission_card.jsx";
import {permissionsRequest} from "../requests/permissions_request.js";

export function PermissionsPane() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const selectTranslatedPermissions = createSelector(
    flatSelector("permissions"), ap => ap.map(p => {
      return {
        id: p.id,
        action: intl.formatMessage({id: "permissions.property.action." + p.action}),
        target: intl.formatMessage({id: "permissions.property.target." + p.target}),
        targetType: intl.formatMessage({id: "permissions.property.targetType." + p.targetType}),
        targetCreatedBy: intl.formatMessage({id: "permissions.property.targetCreatedBy." + p.targetCreatedBy}),
        targetOrganization: intl.formatMessage({id: "permissions.property.targetOrganization." + p.targetOrganization}),
        targetIdentifier: p.targetIdentifier ?? intl.formatMessage({id: "permissions.property.targetIdentifier.null"}),
        description: intl.formatMessage({id: "permissions.property.description." + [
          p.action, p.target, p.targetType, p.targetCreatedBy, p.targetOrganization
        ].filter(x => x).join(".") + ((p.targetIdentifier) ? ".identified" : "")}, {identifier: p.targetIdentifier})
      };
    })
  );
  const selectOrderedPermissions = createSelector(
    selectTranslatedPermissions, tp => _.orderBy(
      tp, ["target", "action", "targetType", "targetCreatedBy", "targetOrganization", "targetIdentifier"])
  );
  const permissions = useSelector(selectOrderedPermissions);
  const sessions = useSelector(s => s.environmentSessions);

  useEffect(() => {reloadOne("Permission", permissionsRequest, dispatch, sessions)}, []);

  return (
    <div className="permissions-pane pane focusable-collection">
      <div className="permissions-title pane-title">
        {intl.formatMessage({id: "permissions.title"})}
      </div>
      <table className="permissions-table">
        <thead>
          <tr>
            <th>Action</th>
            <th>Target</th>
            <th>Type</th>
            <th>Creator</th>
            <th>Organization</th>
            <th>Identifier</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {permissions.map(permission => (
            <PermissionCard key={permission.id} permission={permission}/>
          ))}
        </tbody>
      </table>
    </div>
  );
}


const divMod = (n, m) => [Math.floor(n / m), n % m];

export function formatDuration(duration) {
  if (!duration && duration !== 0) {
    return "n/a";
  }
  let hours, minutes, seconds;
  let milliseconds = duration;

  [hours, milliseconds] = divMod(milliseconds, 36e5);
  [minutes, milliseconds] = divMod(milliseconds, 6e4);
  [seconds, milliseconds] = divMod(milliseconds, 1e3);
  seconds = Math.round(10 * (seconds + (milliseconds / 1000))) / 10.0;

  if (hours !== 0) {
    return `${hours}h ${minutes}m ${seconds.toFixed(1)}s`;
  }

  if (minutes !== 0) {
    return `${minutes}m ${seconds.toFixed(1)}s`;
  }

  if (seconds !== 0) {
    return seconds.toFixed(1) + "s";
  }

  return "n/a";
}

import React, {useState} from "react";

export function UserCard(props) {
  const [focused, setFocused] = useState(false);
  const handleClick = () => {
    setFocused(!focused);
    props.onClick();
  };

  let className = "user-card card";
  if (focused) {
    className += " focused";
  }
  if (props.selected) {
    className += " selected";
  }
  if (props.inAnySelected) {
    className += " in-any-selected";
  }
  if (props.inAllSelected) {
    className += " in-all-selected";
  }
  if (props.adding) {
    className += " adding";
  }
  if (props.removing) {
    className += " removing";
  }
  if (props.editing) {
    className += " editing";
  }
  return (
    <div className={className} onClick={handleClick}>
      <div className="editing-decoration">! CHANGING</div>
      <div className="adding-decoration">+ ADDING</div>
      <div className="removing-decoration">- REMOVING</div>
      <div className="user-full-name">{props.user.fullName}</div>
      <div className="user-email">{props.user.email}</div>
    </div>
  );
}

import localForage from "localforage";
import {combineReducers} from "redux";
import {reducer as router} from "@minimum/router";
import {reducer as tabbed} from "@minimum/tabbed";

import {reducer as accessControlSession} from "./slice/access_control_session_slice.js";
import {reducer as agreements} from "./slice/agreements_slice.js";
import {reducer as artifacts} from "./slice/artifacts_slice.js";
import {reducer as calibrations} from "./slice/calibrations_slice.js";
import {reducer as compilers} from "./slice/compilers_slice.js";
import {reducer as controlServers} from "./slice/control_servers_slice.js";
import {reducer as environmentSessions} from "./slice/environment_sessions_slice.js";
import {reducer as filters} from "./slice/filters_slice.js";
import {reducer as jobs} from "./slice/jobs_slice.js";
import {reducer as metrics} from "./slice/metrics_slice.js";
import {reducer as permissions} from "./slice/permissions_slice.js";
import {reducer as preferences} from "./slice/preferences_slice.js";
import {reducer as queues} from "./slice/queues_slice.js";
import {reducer as reservations} from "./slice/reservations_slice.js";
import {reducer as roles} from "./slice/roles_slice.js";
import {reducer as simulators} from "./slice/simulators_slice.js";
import {reducer as userInterface} from "./slice/user_interface_slice.js";
import {reducer as users} from "./slice/users_slice.js";

const combinedReducer = combineReducers({
  accessControlSession,
  agreements,
  artifacts,
  calibrations,
  compilers,
  controlServers,
  environmentSessions,
  filters,
  jobs,
  metrics,
  permissions,
  preferences,
  queues,
  reservations,
  roles,
  router,
  tabbed,
  simulators,
  userInterface,
  users
});

export const rootReducer = (state, action) => {
  if (action.type === "CLEAR") {
    localForage.ready().then(() => {
      localForage.setDriver(localForage.INDEXEDDB);
      localForage.clear();
    });
    state = undefined;
  }
  return combinedReducer(state, action);
}

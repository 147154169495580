import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {AccountReservationCard} from "./account_reservation_card.jsx";
import {AccountReservationCardNewComplex} from "./account_reservation_card_new_complex.jsx";
import {flatEnvironmentSelector} from "../utility/flat_environment_selector.js";
import {reloadOne} from "../requests/reload_one.js";
import {controlServersRequest} from "../requests/control_servers_request.js";
import {reservationsRequest} from "../requests/reservations_request.js";

export function AccountReservationsPane() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [includeArchived, setIncludeArchived] = useState(false);

  const selectCurrentUsersReservations = createSelector(
    flatEnvironmentSelector("reservations"),
    s => s.accessControlSession,
    (rs, se) => rs.filter(r => r.recipient?.id === se.user.id)
  );

  const selectFilteredReservations = createSelector(
    selectCurrentUsersReservations,
    ur => (includeArchived) ? ur : ur.filter(r => !r.archivedAt)
  );
  const selectOrderedReservations = createSelector(
    selectFilteredReservations, u => _.orderBy(u,
      ["activateAt", "duration", "createdAt"],
      ["desc", "desc", "desc"]
    )
  );
  const reservations = useSelector(selectOrderedReservations);
  const session = useSelector(s => s.accessControlSession);
  const sessions = useSelector(s => s.environmentSessions);

  let filter = {reservations: {createdBy: {id: session.user.id}}}
  if (!includeArchived) {
    filter.reservations.archived = false;
  }

  const reload = () => {
    reloadOne("ControlServer", controlServersRequest, dispatch, sessions);
    reloadOne("Reservation", reservationsRequest, dispatch, sessions, filter);
  }
  useEffect(() => {reload();}, [includeArchived]);

  const handleToggleArchived = () => setIncludeArchived(!includeArchived);

  return (
    <div className="account-reservations-pane pane focusable-collection">
      <div className="account-reservations-title pane-title">
        {intl.formatMessage({id: "account.reservations.title"})}
      </div>
      <div className="account-reservations-options">
        <div className="account-reservations-option">
          <label className="account-reservations-option-include-archived">
            <input className="account-reservations-option-include-archived"
                   type="checkbox"
                   onChange={handleToggleArchived}
                   checked={includeArchived}/>
            <span className="label-text">
              {intl.formatMessage({id: "account.reservations.options.includeArchived.checkbox.label"})}
            </span>
          </label>
        </div>
      </div>
      <div className="account-reservations-list">
        <AccountReservationCardNewComplex reload={reload}/>

        {reservations.map(r => (
          <AccountReservationCard key={r.id} reservation={r} reload={reload}/>
        ))}
      </div>
    </div>
  );
}

import React from "react";
import {useIntl} from "react-intl";

import {TimestampDisplay} from "../display/timestamp_display.jsx";

export function TokenLastUsedDisplay(props) {
  const token = props.token;
  const intl = useIntl();

  return (
    <div className="token-last-used display">
      <span className="token-last-used-label">
        {intl.formatMessage({id: "tokens.token.lastUsed.label"})}
      </span>
      <TimestampDisplay
          empty={intl.formatMessage({id: "tokens.token.lastUsed.never"})}
          value={token.lastUsedAt}/>
    </div>
  );
}

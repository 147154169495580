import _camelCase from "lodash/fp/camelCase.js";
import _curryN from "lodash/fp/curryN.js";
import _get from "lodash/fp/get.js";
import _merge from "lodash/fp/merge.js";
import _set from "lodash/fp/set.js";
import _snakeCase from "lodash/fp/snakeCase.js";
import jtgq from "json-to-graphql-query";
import pluralize from "pluralize";

import {addTypeName} from "./add_type_name.js";
import {postGraphQL} from "./post_graphql.js";
import {deserialize} from "./deserialize.js";
import {orderBy} from "./order_by.js";

export const query = _curryN(4, (metadata, session, properties, filters, orders = []) => {
  const queryName = _camelCase(pluralize(_snakeCase(metadata.typeName).replace("_", " ")));
  const allProperties = addTypeName(_merge(metadata.properties.default, properties));
  let query = {[queryName]: {...allProperties}};
  query = applyFilters(query, filters);
  query = applyOrders(query, orders);

  return postGraphQL(session, jtgq.jsonToGraphQLQuery({query}), {})
  .then((p) => deserialize(p.data[queryName]));
});

function applyFilters(properties, filters) {
  return Object.keys(filters).reduce((r, k) => {
    const f = _get(k, filters);
    return (f) ? _set(k + ".__args.filters", f, r) : r;
  }, properties);
}

function applyOrders(properties, orders) {
  return Object.keys(orders).reduce((r, k) => {
    const o = _get(k, orders);
    return (o) ? _set(k + ".__args.orderBy", orderBy(o), r) : r;
  }, properties);
}

import {origin} from "./origin.js";

export function processErrors (errors) {
  return errors.map((e) => {
    return Object.assign({}, e, {
      code: e.extensions?.origin?.code,
      detail: e.extensions?.origin?.detail,
      id: e.extensions?.id,
      name: e.extensions?.origin?.name,
      origin: origin(e)
    });
  });
}

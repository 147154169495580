import React, {useContext, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";
import {useIntl} from "react-intl";

import {ConnectionsContext} from "../context/connections_context.js";
import {reloadAll} from "../requests/reload_all.js";
import {filterJobs} from "./filter_jobs.js";
import {flatEnvironmentSelector} from "../utility/flat_environment_selector.js";
import {flatSelector} from "../utility/flat_selector.js";
import {hydrateJobs} from "./hydrate_jobs.js";
import {JobCard} from "./job_card.jsx";
import {JobErrorBoundary} from "./job_error_boundary.jsx";
import {orderByReceivedAt} from "../utility/order_by_received_at.js";
import {setUserInterface} from "../slice/user_interface_slice.js";
import {JobsPreferencesTile} from "./jobs_preferences_tile.jsx";

// import {selectFakeControlServers} from "../../fake_data/select_fake_control_servers.js";
// import {selectFakeJobs} from "../../fake_data/select_fake_jobs.js";
// import {selectFakeUsers} from "../../fake_data/select_fake_users.js";
import {CONFIRM_DESTRUCTIVE_TIMEOUT} from "../constants.js";

export function JobsPane(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [selected, setSelected] = useState(new Set());
  const [filteredSelection, setFilteredSelection] = useState(new Set());
  const [selecting, setSelecting] = useState(false);
  const [confirmingCancelSelected, setConfirmingCancelSelected] = useState(false);
  const [confirmTimeout, setConfirmTimeout] = useState(null);

  const {connections} = useContext(ConnectionsContext);
  const session = useSelector(s => s.accessControlSession);
  const sessions = useSelector(s => s.environmentSessions);

  const showEnvironment = useSelector(
    s => Object.keys(s.environmentSessions).length > 1
  );
  const showHeader = useSelector(
    s => s.preferences?.["preferences.qwa.jobs.headerRow"]
  );
  const showJobCreatorInSummary = useSelector(
    s => s.preferences?.["preferences.qwa.jobs.columns.creator"]
  );
  const showJobCreatorInDetails = session.user.roles.some(r => {
    return r.permissions.find(p => {
      return p.target === "jobs" && p.action === "read";
    });
  });

  const limit = useSelector(
    s => s.userInterface?.["jobs.limit"] ?? 50
  );

  // const selectControlServers = selectFakeControlServers;
  // const selectJobs = selectFakeJobs;
  // const selectUsers = selectFakeUsers;

  const selectControlServers = flatEnvironmentSelector("controlServers");
  const selectJobs = flatEnvironmentSelector("jobs");
  const selectUsers = flatSelector("users");

  const selectArtifacts = flatEnvironmentSelector("artifacts");
  const selectQueues = flatEnvironmentSelector("queues");
  const selectSearch = createSelector(s => s, s => s.userInterface?.search);
  const selectSimulators = flatEnvironmentSelector("simulators");

  const selectFilteredJobs = createSelector(
    selectJobs,
    selectSearch,
    selectControlServers,
    selectUsers,
    filterJobs
  );
  const selectOrderedJobs = createSelector(
    selectFilteredJobs, orderByReceivedAt
  );
  const selectLimitedJobs = createSelector(
    selectOrderedJobs, oj => oj.slice(0, limit)
  );
  const selectIncompleteJobs = createSelector(
    selectLimitedJobs, lj => lj.filter(j => !j.exitedAt)
  );
  const selectHydratedJobs = createSelector(
    selectArtifacts,
    selectControlServers,
    selectQueues,
    selectSimulators,
    selectUsers,
    selectLimitedJobs,
    hydrateJobs
  );
  const allJobs = useSelector(selectJobs);
  const filteredJobs = useSelector(selectFilteredJobs);
  const incompleteJobs = useSelector(selectIncompleteJobs);
  const jobs = useSelector(selectHydratedJobs);
  const search = useSelector(selectSearch);

  const handleClearSearch = () => dispatch(setUserInterface({"search": ""}));

  const handleToggleSelected = (id) => (ev) => {
    ev.stopPropagation();
    const job = jobs.find(j => j.id === id);
    if (!job || job.exitedAt) {
      return;
    }
    const changed = new Set(selected)
    const removed = changed.delete(id);
    if (!removed) {
      changed.add(id);
    }
    setSelected(changed);
  };

  const handleSelectAll = () => {
    setSelecting(true);
    setSelected(new Set(incompleteJobs.map(j => j.id)));
  };
  const handleSelectNone = () => setSelected(new Set());
  const handleSelecting = (ev) => setSelecting(ev.target.checked);

  const handleClickCancelSelected = () => {
    setConfirmingCancelSelected(true);
    setConfirmTimeout(setTimeout(() => {
      setConfirmingCancelSelected(false);
      clearTimeout(confirmTimeout);
      setConfirmTimeout(null);
    }, CONFIRM_DESTRUCTIVE_TIMEOUT));
  };

  const handleClickConfirmCancelSelected = async () => {
    clearTimeout(confirmTimeout);
    const cancellations = [];
    filteredSelection.forEach(async (jid) => {
      const job = jobs.find(j2 => j2.id === jid);
      if (!job.exitedAt) {
        await Aqumen.Job.signal("terminate", job.session, job, connections[job.session.url]);
      }
      cancellations.push(Promise.resolve());
    });
    await Promise.all(cancellations);
    setConfirmingCancelSelected(false);
    setSelected(new Set());
    setConfirmTimeout(null);
  };

  const handleClickLimitDecrease = () => {
    dispatch(setUserInterface({"jobs.limit": limit - ((limit <= 50) ? 10 : 50)}));
  }
  const handleClickLimitIncrease = (ev) => {
    dispatch(setUserInterface({"jobs.limit": limit + ((limit < 50) ? 10 : 50)}));
    // ev.target.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });;
  }

  useEffect(() => reloadAll(dispatch, sessions), []);
  useEffect(() => {
    let filtered = new Set(selected);
    filtered.forEach(id => {
      if (!jobs.find(j => j.id === id)) {
        filtered.delete(id);
      }
    });
    setFilteredSelection(filtered);
  }, [selected, search]);

  const isInitial = useRef(true);
  useEffect(() => {
    if (isInitial.current) {
      isInitial.current = false;
    } else {
      document.getElementById("jobs-limit-actions").scrollIntoView(false);
    }
  }, [limit]);

  let jobLimitClassName = "jobs-limit-current";
  if (filteredJobs.length !== allJobs.length && filteredJobs.length > limit) {
    jobLimitClassName += " search-occluded";
  }

  return (
    <div className={"jobs-pane pane " + (props.className ?? "")}>

      {search && jobs.length === 0 && (
        <div className="search-reminder empty">
          <span className="search-reminder-copy">
            {intl.formatMessage({id: "jobs.search.noResult"}, {search})}
          </span>
          <button className="search-reminder-clear-button" onClick={handleClearSearch}>
            {intl.formatMessage({id: "jobs.search.clear.button"})}
          </button>
        </div>
      )}
      {search && jobs.length > 0 && (
        <div className="search-reminder">
          <span className="search-reminder-copy">
            {intl.formatMessage({id: "jobs.search.someResults"}, {search})}
          </span>
          <button className="search-reminder-clear-button" onClick={handleClearSearch}>
            {intl.formatMessage({id: "jobs.search.clear.button"})}
          </button>
        </div>
      )}

      <div className="cancel-many">
        <JobsPreferencesTile/>

        <label className="toggle-selection checkbox">
          <input type="checkbox" onChange={handleSelecting} checked={selecting} disabled={jobs.length <= 0}/>
          <span className="label-text">Select jobs for cancellation</span>
        </label>
        {selecting && (
          <React.Fragment>
            <button className="cancel-many-action select-all" onClick={handleSelectAll} disabled={!selecting || incompleteJobs.length <= 0 || filteredSelection.size >= incompleteJobs.length}>Select All</button>
            <button className="cancel-many-action select-none" onClick={handleSelectNone} disabled={!selecting || filteredSelection.size <= 0}>Select None</button>
            {!(confirmingCancelSelected )&& (
              <button className="cancel-many-action cancel-selected" onClick={handleClickCancelSelected} disabled={!selecting || filteredSelection.size <= 0}>
                Cancel Selected{(selecting && filteredSelection.size > 0) ? ` (${filteredSelection.size})` : ""}
              </button>
            )}
            {confirmingCancelSelected && (
              <button className="cancel-many-action confirm-cancel-selected confirming" onClick={handleClickConfirmCancelSelected}>Confirm Cancel ({filteredSelection.size}) …</button>
            )}
          </React.Fragment>
        )}
      </div>
      <div className={"jobs-grid " + ((showJobCreatorInSummary) ? " show-creator-in-summary" : "")}>
        {showHeader && jobs.length > 0 && (
          <div className="jobs-columns-headers">
            <div className="jobs-column-header job-name-header">
              Job
            </div>
            {showJobCreatorInSummary && (
              <div className="jobs-column-header">
                Creator
              </div>
            )}
            <div className="jobs-column-header">
              Target
            </div>
            <div className="jobs-column-header">
              Type
            </div>
            <div className="jobs-column-header job-last-status-header">
              Activity
            </div>
          </div>
        )}
        <ol className={"jobs-list list " + (props.className ?? "")}>
          {jobs.map((job) => (
            <li className="jobs-item item" key={job.id}>
              <JobErrorBoundary job={job}>
                <JobCard job={job}
                  showEnvironment={showEnvironment}
                  showCreatorInDetails={showJobCreatorInDetails}
                  showCreatorInSummary={showJobCreatorInSummary}
                  selected={filteredSelection.has(job.id)}
                  selecting={selecting}
                  handleToggle={handleToggleSelected(job.id)}
                  handleSummaryClick={(selecting) ? handleToggleSelected(job.id) : null}/>
              </JobErrorBoundary>
            </li>
          ))}
        </ol>
      </div>
      <div className="jobs-limit-actions" id="jobs-limit-actions">
        <div className="i-hate-css-wrapper">
          <button className="jobs-limit-action decrease"
                  disabled={limit <= 10}
                  onClick={handleClickLimitDecrease}>
            -
          </button>
          <span className={jobLimitClassName}>
            jobs&nbsp;
            {filteredJobs.length !== allJobs.length && (
              <span>{filteredJobs.length}&nbsp;/&nbsp;</span>
            )}{allJobs.length}  - <span className="limit">limit {limit}</span>
          </span>
          <button className="jobs-limit-action decrease"
                  onClick={handleClickLimitIncrease}>
            +
          </button>
        </div>
      </div>
    </div>
  );
}

export const permissionsRequest = [{
  id: true,
  version: true,
  action: true,
  level: true,
  target: true,
  targetCreatedBy: true,
  targetIdentifier: true,
  targetOrganization: true,
  targetType: true,

  description: true
}];

export const metadata = Object.freeze({
  typeName: "AcceptedAgreement",
  properties: {
    default: {
      id: true,
      version: true,

      acceptedAt: true,
      agreement: {id: true},
      // user: {id: true}
    }
  }
});

import _curryN from "lodash/fp/curryN.js";
import _first from "lodash/fp/first.js";
import FormData from "form-data";

import {all} from "./all.js";
import {fetch} from "../service/fetch.js";
import {metadata} from "./metadata.js";
import {mutation} from "../service/mutation.js";
import Utility from "../utility/index.js";

export const create = _curryN(4, (session, environment, stream, size, properties = {}) => {
   return mutation(
    metadata,
    session,
    "create",
    properties,
    Utility.create(metadata.typeName, session, environment)
  ).then((created) => {
    const path = `/environments/${created.id}/file`;
    const form = new FormData();
    form.append("file", stream, {
      contentType: "application/octet-stream",
      knownLength: size
    });
    return fetch(session, "PUT", path, form)
    .then(() => all(session, {}, {"environments": {id: created.id}}))
    .then(_first);
  });
});

import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {CONFIRM_DESTRUCTIVE_TIMEOUT} from "../constants.js";

export function RoleArchiveButton(props) {
  const intl = useIntl();

  const [confirming, setConfirming] = useState(false);
  const session = useSelector(s => s.accessControlSession);

  const toggleConfirming = () => {
    setConfirming(true);
    props.setConfirming?.(true);
    setTimeout(() => {
      setConfirming(false);
      props.setConfirming?.(false);
    },CONFIRM_DESTRUCTIVE_TIMEOUT);
  };

  const toggleArchived = async () => {
    const archivedAt = (props.role.archivedAt) ? null : Date.now();
    await Aqumen.Role.update(session, {id: props.role.id}, {archivedAt});
    await props.reload();
    props.setConfirming?.(false);
    setConfirming(false);
  };

  if (!props.role.archivedAt && !confirming) {
    return (
      <div className="role-archive">
        <button className="role-archive-button" onClick={toggleConfirming} disabled={props.disabled}>
          {intl.formatMessage({id: `roles.role.archive.button`})}
        </button>
      </div>
    );
  }

  if (!props.role.archivedAt && confirming) {
    return (
      <div className="role-archive">
        <button className="role-archive-button confirming" onClick={toggleArchived} disabled={props.disabled}>
          {intl.formatMessage({id: `roles.role.archive.confirm.button`})}
        </button>
      </div>
    );
  }

  if (props.role.archivedAt && !confirming) {
    return (
      <div className="role-archive">
        <button className="role-unarchive-button" onClick={toggleConfirming}>
          {intl.formatMessage({id: `roles.role.unarchive.button`})}
        </button>
      </div>
    );
  }

  if (props.role.archivedAt && confirming) {
    return (
      <div className="role-archive confirming">
        <button className="role-unarchive-button confirming" onClick={toggleArchived}>
          {intl.formatMessage({id: `roles.role.unarchive.confirm.button`})}
        </button>
      </div>
    );
  }
}

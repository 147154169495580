import React, {useContext, useState} from "react";
import {useDispatch} from "react-redux";
import {useIntl} from "react-intl"

import {CONFIRM_DESTRUCTIVE_TIMEOUT} from "../constants.js";
import {ConnectionsContext} from "../context/connections_context.js";


export function NavigationButtonSignOut() {
  const dispatch = useDispatch();
  const intl = useIntl()
  const {connections, setConnections} = useContext(ConnectionsContext);
  const [confirm, setConfirm] = useState(false);

  const signOut = () => {
    if (confirm) {
      Object.values(connections).forEach(ws => ws.close());
      dispatch({type: "CLEAR"});
      setConnections({});
      setConfirm(false);
      return;
    }

    setConfirm(true);
    setTimeout(() => setConfirm(false), CONFIRM_DESTRUCTIVE_TIMEOUT);
  }

  let className = "button navigation sign-out";
  let labelId = "navigation.button.signOut";
  if (confirm) {
    className += " confirm";
    labelId += ".confirm";
  }

  return (
    <button className={className} onClick={signOut}>
      {intl.formatMessage({id: labelId})}
    </button>
  );
}

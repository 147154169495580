import {metadata} from "./metadata.js";
import {mutation} from "../service/mutation.js";
import Utility from "../utility/index.js";

export function create(session, reservation, properties = {}) {
  return mutation(
    metadata,
    session,
    "create",
    properties,
    Utility.create(metadata.typeName, session, reservation)
  );
}

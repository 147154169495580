import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useIntl} from "react-intl";

import IconSettings from "../../assets/icons/icon_settings.svg";
import {JobsPreferenceToggle} from "./jobs_preference_toggle.jsx";

export function JobsPreferencesTile(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [open, setOpen] = useState(false);

  const handleClickPreferences = () => {
    setOpen(!open);
  }

  let className = "jobs-preferences-tile";
  className += (open) ? " open" : " closed";

  return (
    <div className={className}>
      <div className="jobs-preferences-backdrop" onClick={handleClickPreferences}></div>
      <div className="jobs-preferences-container">
        <button className="jobs-preferences-button" onClick={handleClickPreferences}>
          <IconSettings aria-label={intl.formatMessage({id: "jobs.preferences.button.aria.label"})}/>
        </button>

        <div className="jobs-preferences">
          <h2 className="jobs-preferences-title" onClick={handleClickPreferences}>
            {intl.formatMessage({id: "jobs.preferences.title"})}
          </h2>
          <JobsPreferenceToggle preference="headerRow"/>
          <JobsPreferenceToggle preference="columns.creator"/>
          <JobsPreferenceToggle preference="timestamps.seconds"/>
        </div>
      </div>
    </div>
  );
}

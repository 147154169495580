import localForage from "localforage";
import {createRoot} from "react-dom/client";

import {rootProvider} from "./root_provider.jsx";

import "./style/boilerplate/main.css";
import "./style/boilerplate/normalize.css";
import "./style/html.scss";

import "./style/color_palette.scss";
import "./style/color_foundation.scss";
import "./style/color_scheme.scss";

import "./style/common.scss";
import "./style/keyframes.scss";
import "./style/layout.scss";
import "./style/typography.scss";

import "./style/errors.scss";
import "./style/forms.scss";
import "./style/navigation_animation.scss";
import "./style/navigation_menu.scss";
import "./style/tabs.scss";

import "./style/panes/about.scss";
import "./style/panes/access_control.scss";
import "./style/panes/account_passkeys.scss";
import "./style/panes/account_reservations.scss";
import "./style/panes/account.scss";
import "./style/panes/agreements.scss";
import "./style/panes/authentication.scss";
import "./style/panes/coming_soon.scss";
import "./style/panes/dashboard.scss";
import "./style/panes/jobs.scss";
import "./style/panes/permissions.scss";
import "./style/panes/roles.scss";
import "./style/panes/systems.scss";
import "./style/panes/tokens.scss";
import "./style/panes/users.scss";

const ONE_HOUR_MS = 60 * 60 * 1000;

const agentMatches = window.navigator.userAgent.match(/Version\/([\d.]+)/);
globalThis.OPERATING_SYSTEM = (agentMatches && agentMatches[1]) ? "iPadOS " + agentMatches[1].replace(/_/g, ".") : "Unknown";

localForage.ready().then(() => {
  localForage.setDriver(localForage.INDEXEDDB);

  if (process.env.APP_NAME) {
    const metaTitle = Array.from(document.getElementsByTagName("meta")).find((e) => {
      return e.name === "apple-mobile-web-app-title";
    });
    metaTitle.content = process.env.APP_NAME;
    document.title = process.env.APP_NAME;
  }

  navigator.serviceWorker
  .register("service_worker.js", {scope: "./"})
  .then((r) => {
    console.debug("Index: Registered", r);
    setInterval(() => {
      console.debug("Index: Registration.update");
      r.update();
    }, ONE_HOUR_MS);
  });

  const root = createRoot(document.getElementById("app-container"));
  rootProvider(root, localForage);
});

import _ from "lodash";
import React from "react";

import {DurationDisplay} from "./duration_display.jsx";

export function JobDurationDisplay(props) {
  const endedAt = props.job.endedAt ?? props.job.exitedAt;

  let className = "job-duration-display clock";
  if (endedAt) {
    className += " executing";
  }
  return (
    <span className={className}>
      <DurationDisplay startedAt={props.job.startedAt} endedAt={endedAt}/>
    </span>
  );
};

import React from "react";

import {useIntl} from "react-intl"

import IconHamburger from "../../assets/icons/icon_hamburger.svg";

export function NavigationButtonMenuToggle(props) {
  const intl = useIntl();
  return (
    <button className="button navigation navigation-menu-toggle" onClick={props.toggleMenuOpen}>
      <IconHamburger aria-label={intl.formatMessage({id: "aria.label.icon.navigation-menu"})}/>
    </button>
  );
}

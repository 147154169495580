import _curryN from "lodash/fp/curryN.js";

import {metadata} from "./metadata.js";
import {mutation} from "../service/mutation.js";

export const terminateAll = _curryN(2, (session, controlServer, properties = {}) => {
  return mutation(
    metadata, session, "terminateAllJobs", properties, controlServer
  );
});

export const metadata = Object.freeze({
  typeName: "Permission",
  properties: {
    default: {
      id: true,
      version: true,
      action: true,
      level: true,
      target: true,
      targetType: true,
      targetCreatedBy: true,
      targetOrganization: true,
      targetIdentifier: true
    }
  }
});

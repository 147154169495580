import _after from "lodash/fp/after.js";
import _cloneDeepWith from "lodash/fp/cloneDeepWith.js";
import _isArray from "lodash/fp/isArray.js";
import _isPlainObject from "lodash/fp/isPlainObject.js";
import _map from "lodash/fp/map.js";

import {processJSON} from "./process_json.js";

const deserializer = _cloneDeepWith(function cloner(v) {
  return _isPlainObject(v)
      ? _cloneDeepWith(_after(cloner, 2), processJSON(JSON.parse, v))
      : undefined;
});

export function deserialize(data) {
  return (_isArray(data)) ? _map(deserializer, data) : deserializer(data);
}

import React from "react";

import {AccountPropertyAzureID} from "./account_property_azure_id.jsx";
import {UserEmailTile} from "../users/user_email_tile.jsx"
import {UserFullNameTile} from "../users/user_full_name_tile.jsx"


export function AccountPropertiesPanel(props) {
  return (
    <div className="account-properties-panel">
      <UserEmailTile user={props.user} messagePrefix="account.properties"/>
      <UserFullNameTile user={props.user} messagePrefix="account.properties"/>
      <AccountPropertyAzureID user={props.user}/>
    </div>
  );
}

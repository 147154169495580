import {AqumenError} from "./aqumen_error.js";
import {processErrors} from "./process_errors.js";

export class JobError extends AqumenError {
  constructor(errors) {
    const processed = processErrors(errors);
    const first = processed[0];
    super({...first, errors: processed, name: "JobError"});
  }
}

import _curryN from "lodash/fp/curryN.js";

import {mutation} from "../service/mutation.js";
import {metadata} from "./metadata.js";
import Utility from "../utility/index.js";

export const create = _curryN(2, (session, token, properties = {}) => {
  return mutation(
    metadata,
    session,
    "create",
    properties,
    Utility.create(metadata.typeName, session, token)
  );
});

import _cloneDeep from "lodash/fp/cloneDeep.js";
import _curryN from "lodash/fp/curryN.js";

import {generateID} from "./generate_id.js";

export const create = _curryN(2, (typeName, session, data) => {
  return Object.assign(
    {id: generateID(session), createdAt: Date.now(), version: 0, __typename: typeName},
    _cloneDeep(data)
  );
});

import _merge from "lodash/fp/merge.js";
import crossFetch from "cross-fetch";
import FormData from "form-data";

import {headers} from "./headers.js";
import {SDKError} from "../error/sdk_error.js";
import {ServiceHTTPError} from "../error/service_http_error.js";
import Utility from "../utility/index.js";

const debug = Utility.debug("aqumen:sdk:service:fetch");

export function fetch(session, method, path, body = null) {
  if (!session) {
    return Promise.reject(new SDKError("Missing session"));
  }
  if (!session.url) {
    return Promise.reject(new SDKError("Missing session.url"));
  }
  let h = headers(session);
  if (body instanceof FormData) {
    delete h["Content-Type"];
    h = _merge(h, body.getHeaders());
  }
  debug("headers %O", h);
  return crossFetch(session.url + "/api/v2" + path, {
    body,
    credentials: "include",
    headers: h,
    method,
    mode: "cors",
    withCredentials: true
  }).then((response) => {
    debug("response %O", response);
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    return response.text().then((txt) => {
      return Promise.reject(new ServiceHTTPError(response, txt));
    });
  });
}

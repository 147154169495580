import React from "react";

import {TokenCreatedDisplay} from "./token_created_display.jsx";
import {TokenDescriptionTile} from "./token_description_tile.jsx";
import {TokenExpiresDisplay} from "./token_expires_display.jsx";
import {TokenLastUsedDisplay} from "./token_last_used_display.jsx";
import {TokenDeleteButton} from "./token_delete_button.jsx";
import {ONE_WEEK_MS} from "../constants.js";

export function TokenCard(props) {
  const token = props.token;

  let className = "token-card card";
  if (token.expiresAt <= Date.now()) {
    className += " expired";
  } else if (token.expiresAt < Date.now() + ONE_WEEK_MS) {
    className += " expires-soon";
  }

  return (
    <div className={className}>
      <TokenDescriptionTile token={token} reload={props.reload}/>
      <div className="token-actions">
        <TokenDeleteButton token={token} reload={props.reload}/>
      </div>
      <TokenLastUsedDisplay token={token}/>
      <TokenExpiresDisplay token={token}/>
      <TokenCreatedDisplay token={token}/>
    </div>
  )
}

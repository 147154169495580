import _after from "lodash/fp/after.js";
import _cloneDeepWith from "lodash/fp/cloneDeepWith.js";
import _isPlainObject from "lodash/fp/isPlainObject.js";
import _set from "lodash/fp/set.js";

export const addTypeName = _cloneDeepWith(function add(v) {
  return _isPlainObject(v)
      ? _cloneDeepWith(_after(add, 2), _set("__typename", true, v))
      : undefined;
});

import _ from "lodash";
import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {blurOnEnter} from "../utility/blur_on_enter.js";
import {CONFIRM_DESTRUCTIVE_TIMEOUT} from "../constants.js";

function emptyUser() {
  return {email: "", fullName: ""};
}

const MIN_NEW_USER_PROPERTY_LENGTH = {
  email: 3,
  fullName: 2
}

export function NewUserTile(props) {
  const intl = useIntl();

  const [blanks, setBlanks] = useState({email: true, fullName: true});
  const [confirming, setConfirming] = useState(false);
  const [invalids, setInvalids] = useState({email: false, fullName: false});
  const [user, setUser] = useState(emptyUser());
  const [working, setWorking] = useState(false);

  const session = useSelector(s => s.accessControlSession);

  const handleChange = (property) => (ev) => {
    setUser(Object.assign({}, user, {[property]: ev.target.value}));
    if (ev.target.value.length > 0
        && ev.target.value.trim().length < MIN_NEW_USER_PROPERTY_LENGTH[property]) {
      setInvalids({...invalids, [property]: true});
    } else if (Aqumen.Utility.isBlank(ev.target.value)) {
      setBlanks({...blanks, [property]: true});
      setInvalids({...invalids, [property]: false});
    } else {
      setBlanks({...blanks, [property]: false});
      setInvalids({...invalids, [property]: false});
    }
  };

  const handleCreate = async () => {
    setWorking(true);
    try {
      await Aqumen.User.create(session, Aqumen.Utility.create("User", session, {
        ...user,
        // FIXME remove once full access control finished
        roles: await Aqumen.Role.all(session, {id: true}, {roles: {identifier: "Restricted"}}),
        // FIXME: remove identifier, credential when AQS gets null support
        ...{identifier: self.crypto.randomUUID(), credential: self.crypto.randomUUID()}
      }));
      setUser(emptyUser());
      await props.reload();
    } catch (e) {
      console.error(e);
    }
    setWorking(false);
  };

  const handleCreateAndSend = async () => {
    if (!confirming) {
      setConfirming(true);
      setTimeout(() => setConfirming(false), CONFIRM_DESTRUCTIVE_TIMEOUT);
      return;
    }
    setConfirming(false);
    setWorking(true);
    try {
      const newUser = Aqumen.Utility.create("User", session, {
        ...user,
        // FIXME remove once full access control finished
        roles: await Aqumen.Role.all(session, {id: true}, {roles: {identifier: "Restricted"}}),
        // FIXME: remove identifier, credential when AQS gets null support
        ...{identifier: self.crypto.randomUUID(), credential: self.crypto.randomUUID()}
      });
      await Aqumen.User.create(session, newUser);
      setUser(emptyUser());
      const reloadAndSend = [
        props.reload(),
        await fetch(session.url + "/api/v2/webauthn/registration/send", {
          method: "POST",
          credentials: "include",
          headers: {
            "API-Key": session.key,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({id: newUser.id})
        })
      ];
      await Promise.all(reloadAndSend);
    } catch (e) {
      setConfirming(true);
      setTimeout(() => setConfirming(false), CONFIRM_DESTRUCTIVE_TIMEOUT);
    } finally {
      setWorking(false);
    }
  };

  const disabled = working
    || Object.values(blanks).some(v => v)
    || Object.values(invalids).some(v => v);

  let className = Object.keys(invalids).reduce((r, k) => {
    return (invalids[k]) ? (r + " invalid-" + _.kebabCase(k)) : r;
  }, "user-card new focusable-item");
  if (working) {
    className += " working";
  }
  if (confirming) {
    className += " confirming";
  }
  return (
    <div className={className}>
      <div className="user-card-inputs">
        <label className="user-full-name">
          <span className="label-text">
            {intl.formatMessage({id: "users.new.fullName.label"})}
          </span>
          <input className="user-full-name"
                 placeholder={intl.formatMessage({id: "users.new.fullName.placeholder"})}
                 autoFocus={true}
                 type="text"
                 disabled={working}
                 onChange={handleChange("fullName")}
                 onKeyUp={blurOnEnter}
                 value={user.fullName}/>
        </label>
        <label className="user-email">
          <span className="label-text">
            {intl.formatMessage({id: "users.new.email.label"})}
          </span>
          <input className="user-email"
                 placeholder={intl.formatMessage({id: "users.new.email.placeholder"})}
                 type="email"
                 disabled={working}
                 onChange={handleChange("email")}
                 onKeyUp={blurOnEnter}
                 value={user.email}/>
        </label>
      </div>

      <div className="user-card-meta">
        <div className="user-card-actions">
          <div className="user-card-action">
            <button className="user-create-and-send"
                    disabled={disabled}
                    onClick={handleCreateAndSend}>
              {!confirming && intl.formatMessage({id: "users.new.createAndSend.button"})}
              {confirming && intl.formatMessage({id: "users.new.createAndSend.confirm.button"})}
            </button>
          </div>
          <div className="user-card-action">
            <button className="user-create" disabled={disabled} onClick={handleCreate}>
              {intl.formatMessage({id: "users.new.create.button"})}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import _curry from "lodash/fp/curry.js";
import {GraphQLClient} from "graphql-request";

import {headers} from "./headers.js";
import {ServiceAPIError} from "../error/service_api_error.js";
import {ServiceHTTPError} from "../error/service_http_error.js";
import Utility from "../utility/index.js";

const debug = Utility.debug("aqumen:sdk:service:call");

export const postGraphQL = _curry((session, query, variables) => {
  const url = session.url + "/graphql/v2";
  const h = headers(session);
  const gql = [query].join("\n\n");

  if (debug.enabled) {
    let dh = h;
    if (dh.Authorization) {
      dh = Object.assign({}, dh, {Authorization: "[redacted]"});
    }
    debug("with %O", {headers: dh, graphQL: gql, url, variables});
  }
  const client = new GraphQLClient(
    url, {mode: "cors", credentials: "include", headers: h, withCredentials: true}
  );
  return client.request(gql, variables)
  .then((data) => {
    return Promise.resolve({data});
  })
  .catch((payload) => {
    let error;
    if (payload.response) {
      error = new ServiceAPIError(payload.response);
    } else {
      error = new ServiceHTTPError(payload, JSON.stringify(payload));
    }
    return Promise.reject(error);
  });
});

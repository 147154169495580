import _ from "lodash";
import React, {useEffect, useRef, useState} from "react";

import {formatDuration} from "../format/format_duration.js";

export function DurationDisplay(props) {
  const [end, setEnd] = useState(props.endedAt);
  const durationInterval = useRef(null);

  useEffect(() => {
    if (props.endedAt) {
      clearInterval(durationInterval.current);
      setEnd(props.endedAt);
    } else {
      durationInterval.current = setInterval(() => setEnd(Date.now()), 100);
      return () => {
        clearInterval(durationInterval.current);
      };
    }
  }, [props.endedAt]);

  const duration = Math.max(0, end - (props.startedAt ?? end));
  return (
    <React.Fragment>
      {formatDuration(duration)}
    </React.Fragment>
  );
};

export function bin(results) {
  const combinations = 2 ** results.num_qubits;
  const binned = {};

  for (let i = 0; i < combinations; i++) {
    let key = "";
    for (let qubit = 0; qubit < results.num_qubits; qubit++) {
      key += ((i >> qubit) & 1) ? "1" : "0";
    }
    binned[key] = 0;
  }

  for (let shot = 0; shot < results.num_shots; shot++) {
    let bin = "";
    for (let qubit = 0; qubit < results.num_qubits; qubit++) {
      if (shot in results.measurements[qubit]) {
        bin += results.measurements[qubit][shot];
      } else {
        bin += "0";
      }
    }
    binned[bin]++;
  }

  return binned;
}

import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {ArchiveButton} from "./archive_button.jsx";
import {DisplayCountdown} from "../display/display_countdown.jsx";
import {DisplayDuration} from "../display/display_duration.jsx";
import {DisplayId} from "../display/display_id.jsx";
import {TimestampDisplay} from "../display/timestamp_display.jsx";
import {flatEnvironmentSelector} from "../utility/flat_environment_selector.js";
import {formatTimestamp} from "../format/format_timestamp.js";
import {ONE_DAY_MS} from "../constants.js";

function calculateRelativeActivation(start, end) {
  const now = Date.now();
  if (now >= start && now <= end) {
    return "active";
  }
  if (now > end) {
    return "past";
  }
  if (now + ONE_DAY_MS > start) {
    return "near";
  }

  return "far";
}

export function AccountReservationCard(props) {
  const intl = useIntl();

  const start = props.reservation.activateAt;
  const end = props.reservation.activateAt + props.reservation.duration;

  const [confirmingArchive, setConfirmingArchive] = useState(false);
  const [relativeActivation, setRelativeActivation] = useState(
    calculateRelativeActivation(start, end)
  );

  const selectAllControlServers = flatEnvironmentSelector("controlServers");
  const selectControlServer = createSelector(
    selectAllControlServers,
    acs => acs.find(cs => cs.id === props.reservation.controlServer?.id)
  );
  const controlServer = useSelector(selectControlServer);

  useEffect(() => {
    const iv = setInterval(() => {
      setRelativeActivation(calculateRelativeActivation(start, end));
    }, 100);
    return () => {
      clearInterval(iv);
    };
  }, [relativeActivation]);

  const showMessage = props.reservation.message
    && (props.reservation.approvedAt || props.reservation.rejectedAt);

  let className = "account-reservation-card card focusable-item";
  let messagePrefix = "account.reservations.reservation";
  if (props.reservation.archivedAt) {
    className += " archived";
    messagePrefix += ".archived";
  }
  if (confirmingArchive) {
    className += " confirming confirming-archive";
  }
  let qciMessageClassName = "account-reservation-message";
  if (props.reservation.approvedAt) {
    qciMessageClassName += " approved";
  }
  if (props.reservation.rejectedAt) {
    qciMessageClassName += " rejected";
  }

  const showTiming = props.reservation.approvedAt
    || (props.reservation.activateAt && props.reservation.duration);

  return (
    <div className={className}>
      <div className="account-reservation-card-inputs">
        {showTiming && (
          <div className="account-reservation-card-data">
            <div className="account-reservation-timing">
              <div className="account-reservation-start">
                <span className="data-label">
                  Start
                </span>
                <div className="data-value">
                  <TimestampDisplay value={start}/>
                  {!props.reservation.archivedAt && props.reservation.approvedAt && relativeActivation === "near" && (
                    <div className="account-reservation-imminent start approved">
                      &nbsp;(starts in&nbsp;<DisplayCountdown value={start}/>)
                    </div>
                  )}
                  {!props.reservation.archivedAt && !props.reservation.approvedAt && (
                    <div className="account-reservation-imminent start unapproved">
                      &nbsp;(awaiting confirmation at QCI)
                    </div>
                  )}
                </div>
              </div>
              <div className="account-reservation-end">
                <span className="data-label">
                  End
                </span>
                <span className="data-value">
                  <TimestampDisplay value={end}/>
                  {!props.reservation.archivedAt && relativeActivation === "active" && (
                    <div className="account-reservation-imminent end">
                      &nbsp;(ends in&nbsp;<DisplayCountdown value={end}/>)
                    </div>
                  )}
                </span>
              </div>
              <div className="account-reservation-duration">
                <span className="data-label">
                  Duration
                </span>
                <span className="data-value">
                  <DisplayDuration value={end - start}/>
                </span>
              </div>
              <div className="account-reservation-control-server">
                <span className="data-label">System</span>
                <span className="data-value">{controlServer?.identifier}</span>
              </div>
            </div>
          </div>
        )}

        <div className="account-reservation-description">
          <span className="data-label">
            {intl.formatMessage({id: `account.reservations.reservation.description.label`})}
          </span>
          <span className="data-value">
            {props.reservation.description}
          </span>
        </div>


        {showMessage && (
          <fieldset className={qciMessageClassName}>
            <legend className="account-reservation-message-label">
              {props.reservation.approvedAt
                && intl.formatMessage(
                  {id: `account.reservations.reservation.message.approved.label`},
                  {timestamp: formatTimestamp(props.reservation.approvedAt)}
                )}
              {props.reservation.rejectedAt
                && intl.formatMessage(
                  {id: `account.reservations.reservation.message.rejected.label`},
                  {timestamp: formatTimestamp(props.reservation.rejectedAt)}
                )}
            </legend>
            <div className="account-reservation-message-text">
              {props.reservation.message}
            </div>
          </fieldset>
        )}
      </div>

      <div className="account-reservation-card-meta">
        <div className="account-reservation-card-id">
          <span className="data-label">
            {intl.formatMessage({id: `account.reservations.reservation.id.aqumen.label`})}
          </span>
          <span className="data-value">
            <DisplayId id={props.reservation.id} disabled={confirmingArchive}/>
          </span>
        </div>
        <div className="account-reservation-card-actions">
          <ArchiveButton reservation={props.reservation}
                         reload={props.reload}
                         setConfirming={setConfirmingArchive}/>
        </div>
      </div>
    </div>
  );
}

export function simple(qcdlArguments = {}, shots = 1) {
  return {
    qcdl_args: qcdlArguments,
    run_params: {
      revert: false,
      janus_acquisition: {
        unlimited_streaming: false,
        wait_while_streaming: true,
        iq: {
          averages: 1,
          points: 1,
          num_files: 1
        },
        iqr: {
          averages: 1,
          points: 1,
          num_files: 1
        },
        raw: {
          averages: 1,
          points: 1,
          num_files: 1
        },
        se: {
          averages: shots,
          points: 1,
          num_files: 1
        },
        acquire: ["se"]
      }
    }
  };
}

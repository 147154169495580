export const metadata = Object.freeze({
  typeName: "Reservation",
  properties: {
    default: {
      id: true,
      version: true,
      archivedAt: true,

      activateAt: true,
      duration: true,

      approvedAt: true,
      rejectedAt: true
    }
  }
});

import {isBlank} from "./is_blank.js";

export function formatName(user) {
  if (isBlank(user.email)) {
    return user.fullName;
  }
  if (isBlank(user.fullName)) {
    return user.email;
  }
  return `${user.email} <${user.fullName}>`;
}

import React, {useState} from "react";

export function PermissionCard(props) {
  const [focused, setFocused] = useState(false);
  const handleClick = () => {
    setFocused(!focused);
  };

  let className = "permission-card card";
  if (focused) {
    className += " focused";
  }
  return (
    <tr className={className} onClick={handleClick}>
      <td className="permission-card-action">
        {props.permission.action}
      </td>
      <td className="permission-card-target">
        {props.permission.target}
      </td>
      <td className="permission-card-target-type">
        {props.permission.targetType}
      </td>
      <td className="permission-card-target-created-by">
        {props.permission.targetCreatedBy}
      </td>
      <td className="permission-card-target-organization">
        {props.permission.targetOrganization}
      </td>
      <td className="permission-card-target-identifier">
        {props.permission.targetIdentifier}
      </td>
      <td className="permission-card-target-description">
        {props.permission.description}
      </td>
    </tr>
  );
}

import _ from "lodash";
import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {blurOnEnter} from "../utility/blur_on_enter.js";

function emptyRole() {
  return {identifier: "", description: ""};
}

const MIN_LENGTH = {
  identifier: 3,
  description: 0
}

export function RoleCardNew(props) {
  const intl = useIntl();

  const [blanks, setBlanks] = useState({description: true, identifier: true});
  const [invalids, setInvalids] = useState({description: false, identifier: false});
  const [role, setRole] = useState(emptyRole());
  const [working, setWorking] = useState(false);

  const session = useSelector(s => s.accessControlSession);

  const handleChange = (property) => (ev) => {
    setRole(Object.assign({}, role, {[property]: ev.target.value}));
    if (ev.target.value.length > 0
        && ev.target.value.trim().length < MIN_LENGTH[property]) {
      setInvalids({...invalids, [property]: true});
    } else if (Aqumen.Utility.isBlank(ev.target.value)) {
      setBlanks({...blanks, [property]: true});
      setInvalids({...invalids, [property]: false});
    } else {
      setBlanks({...blanks, [property]: false});
      setInvalids({...invalids, [property]: false});
    }
  };

  const handleCreate = async () => {
    setWorking(true);
    try {
      await Aqumen.Role.create(session, Aqumen.Utility.create("Role", session, role));
      setRole(emptyRole());
      await props.reload();
    } catch (e) {
      console.error(e);
    }
    setWorking(false);
  };

  const disabled = working
    || Object.values(blanks).some(v => v)
    || Object.values(invalids).some(v => v);

  let className = Object.keys(invalids).reduce((r, k) => {
    return (invalids[k]) ? (r + " invalid-" + _.kebabCase(k)) : r;
  }, "role-card new focusable-item");
  if (working) {
    className += " working";
  }
  return (
    <div className={className}>
      <div className="role-card-inputs">
        <label className="role-identifier">
          <span className="label-text">
            {intl.formatMessage({id: "roles.new.identifier.label"})}
          </span>
          <input className="role-identifier"
                 placeholder={intl.formatMessage({id: "roles.new.identifier.placeholder"})}
                 autoFocus={true}
                 type="text"
                 disabled={working}
                 onChange={handleChange("identifier")}
                 onKeyUp={blurOnEnter}
                 value={role.identifier}/>
        </label>
        <label className="role-description">
          <span className="label-text">
            {intl.formatMessage({id: "roles.new.description.label"})}
          </span>
          <input className="role-description"
                 placeholder={intl.formatMessage({id: "roles.new.description.placeholder"})}
                 type="text"
                 disabled={working}
                 onChange={handleChange("description")}
                 onKeyUp={blurOnEnter}
                 value={role.description}/>
        </label>
      </div>

      <div className="role-card-meta">
        <div className="role-card-actions">
          <div className="role-card-action">
            <button className="role-create" disabled={disabled} onClick={handleCreate}>
              {intl.formatMessage({id: "roles.new.create.button"})}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import _curryN from "lodash/fp/curryN.js";
import _first from "lodash/fp/first.js";
import _isEmpty from "lodash/fp/isEmpty.js";
import _last from "lodash/fp/last.js";
import FormData from "form-data";

import {all} from "./all.js";
import {fetch} from "../service/fetch.js";
import {metadata} from "./metadata.js";
import {mutation} from "../service/mutation.js";
import Utility from "../utility/index.js";

export const update = _curryN(3, (session, original, change, stream, size, properties = {}) => {
  const up = [];
  let sendable = Utility.update(original, change);
  delete sendable.createdAt;
  if (!_isEmpty(change)) {
    up.push(mutation(
      metadata,
      session,
      "update",
      properties,
      sendable
    ));
  }
  if (stream) {
    const path = `/environments/${original.id}/file`;
    const form = new FormData();
    form.append("file", stream, {
      contentType: "application/octet-stream",
      knownLength: size
    });
    return fetch(session, "PUT", path, form)
      .then(() => all(session, {}, {"environments": {id: original.id}}))
      .then(_first);
  }
  if (up.length === 0) {
    return original;
  }
  return Promise.all(up).then(_last);
});

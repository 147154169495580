export const schema = Object.freeze({
  "$schema": "http://json-schema.org/schema#",

  "$id": "https://quantumcircuits.com/schema/version-10/aqumen-project.schema.json",

  "definitions": {
    "files": {
      "type": "object",
      "additionalProperties": {
        "$ref": "#/definitions/path"
      },
      "propertyNames": {
        "pattern": "^\\S+$"
      }
    },

    "path": {
      "type": ["string", "null"],
      "pattern": "^[^\\x00]+$"
    },

    "options": {
      "type": "object",
      "propertyNames": {
        "pattern": "^\\S+$"
      },
      "additionalProperties": {
        "type": ["boolean", "integer", "null", "string"]
      }
    }
  },

  "type": "object",
  "additionalProperties": false,
  "required": [
    "environment",
    "files",
    "compile",
    "run",
    "simulate",
    "version"
  ],

  "properties": {

    "environment": {
      "type": "object",
      "additionalProperties": false,
      "properties": {
        "name": {
          "type": "string",
          "pattern": "^\\S+$"
        },
        "path": {
          "$ref": "#/definitions/path"
        }
      },
      "oneOf": [
        {"required": ["name"]},
        {"required": ["path"]}
      ]
    },

    "files": {
      "$ref": "#/definitions/files"
    },

    "compile" : {
      "type": "object",
      "additionalProperties": false,
      "required": ["files", "libraries", "main", "options", "out"],
      "properties": {
        "files": {
          "$ref": "#/definitions/files"
        },
        "libraries" : {
          "type": ["array"],
          "items": {
            "$ref": "#/definitions/path"
          }
        },
        "main": {
          "$ref": "#/definitions/path"
        },
        "options": {
          "$ref": "#/definitions/options"
        },
        "out": {
          "$ref": "#/definitions/path"
        }
      }
    },

    "run": {
      "type": "object",
      "additionalProperties": false,
      "required": ["files", "main", "program", "options", "out"],
      "properties": {
        "files": {
          "$ref": "#/definitions/files"
        },
        "main": {
          "$ref": "#/definitions/path"
        },
        "program": {
          "$ref": "#/definitions/path"
        },
        "options": {
          "$ref": "#/definitions/options"
        },
        "out": {
          "$ref": "#/definitions/path"
        }
      }
    },

    "simulate": {
      "type": "object",
      "additionalProperties": false,
      "required": ["files", "main", "options", "out"],
      "properties": {
        "files": {
          "$ref": "#/definitions/files"
        },
        "main": {
          "$ref": "#/definitions/path"
        },
        "options": {
          "$ref": "#/definitions/options"
        },
        "out": {
          "$ref": "#/definitions/path"
        }
      }
    },

    "version": {
      "type": "integer",
      "const": 10
    }
  }
});

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {CompilerCard} from "./compiler_card.jsx";
import {ControlServerCard} from "./control_server_card.jsx";
import {flatEnvironmentSelector} from "../utility/flat_environment_selector.js";
import {flatSelector} from "../utility/flat_selector.js";
import {hydrateCompilers} from "./hydrate_compilers.js"
import {hydrateControlServers} from "./hydrate_control_servers.js"
import {hydrateSimulators} from "./hydrate_simulators.js"
import {orderByAvailabilityAndIdentifier} from "../utility/order_by_availability_and_identifier.js"
import {set as setFilter, clear as clearFilter} from "../slice/filters_slice.js";
import {SimulatorCard} from "./simulator_card.jsx";
import {SystemErrorBoundary} from "./system_error_boundary.jsx";

// import {selectFakeControlServers} from "../../fake_data/select_fake_control_servers.js";


export function SystemsList(props) {
  const dispatch = useDispatch();

  const filters = useSelector(s => s.filters);
  const showEnvironment = useSelector(s => Object.keys(s.environmentSessions).length > 1);

  const selectControlServers = flatEnvironmentSelector("controlServers");
  // const selectControlServers = selectFakeControlServers;

  const selectCompilers = flatEnvironmentSelector("compilers");
  const selectJobQueues = flatEnvironmentSelector("queues");
  const selectReservations = flatEnvironmentSelector("reservations");
  const selectSimulators = createSelector(
    flatEnvironmentSelector("simulators"), s => s.filter(x => x.type !== "qiskit")
  );
  const selectUsers = flatSelector("users");

  const selectHydratedControlServers = createSelector(
    selectControlServers, selectJobQueues, selectReservations, selectUsers, hydrateControlServers
  );

  const selectOrdered = createSelector(
    selectCompilers,
    selectHydratedControlServers,
    selectSimulators,
    orderByAvailabilityAndIdentifier
  );
  const systems = useSelector(selectOrdered);
  const activeSystemId = useSelector(s => s.filters?.systemDetail?.system?.id)

  if (systems.length === 0) {
    return null;
  }

  const handleItemClick = (system) => () => {
    if (filters?.systemDetail?.system?.id === system.id) {
      return dispatch(clearFilter({type: "systemDetail"}));
    }
    return dispatch(setFilter({type: "systemDetail", filter: {system}}));
  };

  return (
    <ul className={"systems-list list " + (props.className ?? "")}>
      {systems.map((system) => {
        const key = system.__typename + system.session?.id + system.id;
        const isActive = activeSystemId === system.id;
        let itemClassName = "systems-item item";
        if (isActive) {
          itemClassName += " active";
        }
        return (
          <li className={itemClassName} key={key} onClick={handleItemClick(system)}>
            <SystemErrorBoundary system={system}>
              {system.__typename === "Compiler" && (
                <CompilerCard isActive={isActive} compiler={system} showEnvironment={showEnvironment}/>
              )}
              {system.__typename === "ControlServer" && (
                <ControlServerCard isActive={isActive} controlServer={system} showEnvironment={showEnvironment}/>
              )}
              {system.__typename === "Simulator" && (
                <SimulatorCard isActive={isActive} simulator={system} showEnvironment={showEnvironment}/>
              )}
            </SystemErrorBoundary>
          </li>
        );
      })}
    </ul>
  );
}

import _curryN from "lodash/fp/curryN.js";

import {mutation} from "../../service/mutation.js";
import {metadata} from "./metadata.js";

export const restart = _curryN(2, (session, instrument, properties = {}) => {
  return mutation(
    metadata, session, "restart", properties, instrument
  );
});

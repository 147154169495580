import {AqumenError} from "./aqumen_error.js";
import {JobError} from "./job_error.js";
import {SDKError} from "./sdk_error.js";
import {ServiceAPIError} from "./service_api_error.js";
import {ServiceHTTPError} from "./service_http_error.js";
import {ServiceSocketError} from "./service_socket_error.js";

export default {
  AqumenError, JobError, SDKError, ServiceAPIError, ServiceHTTPError, ServiceSocketError
};

import _curry from "lodash/fp/curry.js";

import {fetch} from "../service/fetch.js";

export const send = _curry((url, key, type, id) => {
  let endpoint = null;

  switch (type) {
    case "passkeyRegistration":
      endpoint = "/webauthn/registration/send";
      break;
    default:
      endpoint = "/webauthn/registration/send";
  }

  const payload = JSON.stringify({id});

  return fetch({url, key}, "POST", endpoint, payload)
  .then((response) => {
    return response.json().then((j) => Object.assign({}, j.data, {url, key}));
  });
});

import _ from "lodash";
import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
  name: "Artifact",
  initialState: {},
  reducers: {
    "replace": (state, action) => {
      state[action.session.url] = _.keyBy(action.payload, "id")
    },
    "update": (state, action) => {
      state[action.session.url] ||= {};
      state[action.session.url][action.payload.id] = action.payload;
    }
  },
  extraReducers: (builder) => builder
    .addCase("sessions/deauthenticate", (state, action) => _.omit(state, action.payload.url))
    .addCase("Job/replace", (state, action) => {
      action.payload.forEach(job => job.artifacts.forEach(a => {
        state[action.session.url] ||= {};
        state[action.session.url][a.id] = Object.assign({}, a, {job: {id: job.id}});
      }));
    })
});

export const {reducer} = slice;

import {AqumenError} from "./aqumen_error.js";
import {origin} from "./origin.js";

export class ServiceSocketError extends AqumenError {
  constructor(event) {
    super({
      code: event.error?.code?.toString(),
      detail: event.error?.reason,
      message: event.error?.message || event?.error || event,
      name: "SocketError",
      origin: origin({origin: {type: "sdk"}})
    });
  }
}

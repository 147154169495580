import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {DashboardJobCard} from "./dashboard_job_card.jsx";
import {flatEnvironmentSelector} from "../utility/flat_environment_selector.js";
import {flatSelector} from "../utility/flat_selector.js";
import {hydrateJobs} from "../jobs/hydrate_jobs.js";
import {orderByReceivedAt} from "../utility/order_by_received_at.js";

const LIMIT = 5;

export function DashboardJobsTile(props) {
  const intl = useIntl();

  const session = useSelector(s => s.accessControlSession);

  const selectArtifacts = flatEnvironmentSelector("artifacts");
  const selectControlServers = flatEnvironmentSelector("controlServers");
  const selectJobs = flatEnvironmentSelector("jobs");
  const selectQueues = flatEnvironmentSelector("queues");
  const selectSimulators = flatEnvironmentSelector("simulators");
  const selectUsers = flatSelector("users");

  const selectUsersJobs = createSelector(
    selectJobs, (jobs) => jobs.filter(j => j.createdBy.id === session.user.id)
  );
  const selectOrderedJobs = createSelector(
    selectUsersJobs, orderByReceivedAt
  );
  const selectLimitedJobs = createSelector(
    selectOrderedJobs, oj => oj.slice(0, LIMIT)
  );
  const selectHydratedJobs = createSelector(
    selectArtifacts,
    selectControlServers,
    selectQueues,
    selectSimulators,
    selectUsers,
    selectLimitedJobs,
    hydrateJobs
  );

  const jobs = useSelector(selectHydratedJobs);

  return (
    <div className="tile dashboard-jobs-tile dashboard-section">
      <h2 className="dashboard-jobs-title dashboard-section title">
        {intl.formatMessage({id: "dashboard.jobs.title"})}
      </h2>
      {(jobs.length <= 0) && (
        <div className="dashboard-jobs-empty-message">
          {intl.formatMessage({id: "dashboard.jobs.empty"})}
        </div>
      )}
      <ol className="dashboard-jobs-list">
        {jobs.map((job) => (
          <li className="dashboard-job-item item" key={job.id}>
            <DashboardJobCard job={job}/>
          </li>
        ))}
      </ol>
    </div>
  );
}

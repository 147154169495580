import _curryN from "lodash/fp/curryN.js";

import {metadata} from "./metadata.js";
import {mutation} from "../service/mutation.js";
import Utility from "../utility/index.js";


export const update = _curryN(3, (session, original, change, properties = {}) => {
  return mutation(
    metadata, session, "update", properties, Utility.update(original, change)
  );
});

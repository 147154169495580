import React from "react";
import {useIntl} from "react-intl";

export function ComingSoonPane(props) {
  const intl = useIntl();

  return (
    <div className={"pane coming-soon " + props.label}>
      <h1 className={"coming-soon title " + props.label}>
        {intl.formatMessage({id: `comingSoon.${props.label}.title`}, {em: (c) => (<em>{c}</em>)})}
      </h1>

      <p className={"coming-soon description " + props.label}>
        {intl.formatMessage({id: `comingSoon.${props.label}.description`})}
      </p>
      {props.today && (
        <p className={"coming-soon today " + props.label}>
          {intl.formatMessage({id: `comingSoon.${props.label}.today`}, {command: (c) => (<code>{c}</code>)})}
        </p>
      )}
    </div>
  )
}

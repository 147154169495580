import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {DashboardJobsTile} from "./dashboard_jobs_tile.jsx";
import {DashboardQuickLinksTile} from "./dashboard_quick_links_tile.jsx";
import {DashboardReservationStatusTile} from "./dashboard_reservation_status_tile.jsx";
import {DashboardSystemsUsageTile} from "./dashboard_systems_usage_tile.jsx";
import {reloadAll} from "../requests/reload_all.js";

export function DashboardPane() {
  const sessions = useSelector(s => s.environmentSessions);
  const dispatch = useDispatch();

  useEffect(() => reloadAll(dispatch, sessions), []);

  return (
    <div className="pane dashboard-pane">
      <DashboardReservationStatusTile/>
      <DashboardJobsTile/>
      <DashboardSystemsUsageTile/>
      <DashboardQuickLinksTile/>
    </div>
  );
}

import _curryN from "lodash/fp/curryN.js";

import {metadata} from "./metadata.js";
import {mutation} from "../service/mutation.js";

export const delete_ = _curryN(3, (session, original, properties) => {
  return mutation(
    metadata,
    session,
    "delete",
    properties,
    original
  );
});

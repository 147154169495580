import Aqumen from "@aqumen/sdk";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {flatSelector} from "../utility/flat_selector.js";
import IconDownload from "../../assets/icons/icon_download.svg";
import {selectUserPermissions} from "../utility/select_user_permissions.js";

export function ButtonDownloadArtifact(props) {
  const intl = useIntl();
  let artifactType = props.targetType;
  if (artifactType === "result") {
    artifactType = (props.job.type === "compile") ? "jmz" : "resultJson";
  }
  const findArtifact = (j) => j.artifacts?.find(a => a.type === artifactType);

  let disabled = !Boolean(findArtifact(props.job));
  // console.debug(
  //   "ButtonDownloadArtifact",
  //   {disabled, targetType: artifactType, availableTypes: props.job.artifacts.map(a => a.type)}
  // )

  const selectUsers = flatSelector("users");
  const selectCreatorUser = createSelector(
    selectUsers,
    us => us.find(u => u.id === props.job?.createdBy?.id) || {}
  );
  const currentUser = useSelector(s => s.accessControlSession.user);
  const creatorUser = useSelector(selectCreatorUser);

  let possibleCreatorPermissions;
  if (creatorUser.id === currentUser.id) {
    possibleCreatorPermissions = ["self", "sharesOrganization", "anyone"];
  } else if (currentUser.organization?.id && creatorUser.organization?.id === currentUser.organization?.id) {
    possibleCreatorPermissions = ["sharesOrganization", "anyone"];
  } else {
    possibleCreatorPermissions = ["anyone"];
  }

  const selectButtonVisibiltyPermission = createSelector(
    selectUserPermissions,
    userPermissions => userPermissions.find(p =>
      p.target === "artifacts"
      && p.action === "read"
      && (p.targetType === null || p.targetType === artifactType)
    )
  );
  const selectAuthorizingPermission = createSelector(
    selectUserPermissions,
    userPermissions => userPermissions.find(p =>
      p.target === "artifacts"
      && p.action === "read"
      && (p.targetType === null || p.targetType === artifactType)
      && possibleCreatorPermissions.includes(p.targetCreatedBy)
    )
  );

  const hasButtonVisibiltyPermission = useSelector(selectButtonVisibiltyPermission);
  const hasAllAuthorizingPermissions = useSelector(selectAuthorizingPermission);

  if (!hasButtonVisibiltyPermission) {
    return null;
  }

  if (!hasAllAuthorizingPermissions) {
    disabled = true;
  }

  const handleClick = async () => {
    const latest = await Aqumen.Job.all(
      props.job.session,
      {artifacts: {id: true, type: true, url: true}},
      {jobs: {id: props.job.id}}
    );
    const artifact = findArtifact(latest?.[0]);
    if (artifact?.url) {
      const link = document.createElement('a');
      link.href = artifact.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("artifact missing");
    }
  };

  const className = `download artifact ${props.targetType} ${props.className ?? ""}`;

  return (
    <button disabled={disabled} onClick={handleClick} className={className}>
      <IconDownload aria-label={intl.formatMessage({id: "aria.label.icon.download"})}/>

      <span className="button-text">{props.children}</span>
    </button>
  )
}

export const reservationsRequest = [{
  id: true,
  activateAt: true,
  active: true,
  controlServer: {id: true},
  createdAt: true,
  createdBy: {id: true},
  description: true,
  message: true,
  recipient: {id: true}
}];

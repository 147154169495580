export const metadata = Object.freeze({
  typeName: "Passkey",
  properties: {
    default: {
      id: true,
      name: true,
      version: true
    }
  }
});

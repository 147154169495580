import _after from "lodash/fp/after.js";
import _cloneDeepWith from "lodash/fp/cloneDeepWith.js";
import _isArray from "lodash/fp/isArray.js";
import _isPlainObject from "lodash/fp/isPlainObject.js";
import _map from "lodash/fp/map.js";
import _omit from "lodash/fp/omit.js";

import {processJSON} from "./process_json.js";

export function serialize(data) {
  return (_isArray(data)) ? _map(serializer, data) : serializer(data);
}

const serializer = _cloneDeepWith(function cloner(v) {
  return _isPlainObject(v)
  ? _cloneDeepWith(_after(cloner, 2), _omit("__typename", processJSON(JSON.stringify, v)))
  : undefined;
});

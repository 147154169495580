import _ from "lodash";
import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
  name: "Filters",
  initialState: {},
  reducers: {
    "set": (state, {payload: {type, filter}}) => {
      state[type] = filter;
    },
    "clear": (state, {payload}) => _.omit(state, payload.type)
  }
});

export const {set, clear} = slice.actions;
export const {reducer} = slice;

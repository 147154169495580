import _ from "lodash";
import React from "react";

import {JobDurationDisplay} from "./job_duration_display.jsx";
import {JobQueueDisplay} from "./job_queue_display.jsx";
import {JobTypeDisplay} from "./job_type_display.jsx";

export function JobTimingTile(props) {
  const job = props.job;

  return (
    <div className="job-timing-tile">
      <JobTypeDisplay job={job}/>

      {job.startedAt && (
        <JobDurationDisplay job={job}/>
      )}

      {!job.startedAt && (
        <JobQueueDisplay job={job}/>
      )}
    </div>
  );
}

export const metadata = Object.freeze({
  typeName: "Environment",
  properties: {
    default: {
      id: true,
      version: true,

      createdAt: true,
      archivedAt: true,

      identifier: true,
      name: true,
      description: true,
      runParameters: true
    },
    json: ["runParameters"]
  }
});

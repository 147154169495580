import React, {useState} from "react";
import {useIntl} from "react-intl";

export function PermissionCard(props) {
  const intl = useIntl();
  const [focused, setFocused] = useState(false);

  const handleClick = () => {
    setFocused(!focused);
    props.onClick();
  };

  const description = intl.formatMessage({id: "permissions.property.description." + [
    props.permission.action,
    props.permission.target,
    props.permission.targetType,
    props.permission.targetCreatedBy,
    props.permission.targetOrganization
  ].filter(x => x).join(".") + ((props.permission.targetIdentifier) ? ".identified" : "")}, {identifier: props.permission.targetIdentifier});

  let className = "permission-card card";
  if (focused) {
    className += " focused";
  }
  if (props.selected) {
    className += " selected";
  }
  if (props.inAnySelected) {
    className += " in-any-selected";
  }
  if (props.inAllSelected === true) {
    className += " in-all-selected";
  }
  if (props.adding) {
    className += " adding";
  }
  if (props.removing) {
    className += " removing";
  }
  return (
    <div className={className} onClick={handleClick}>
      <div className="adding-decoration">+ ADDING</div>
      <div className="removing-decoration">- REMOVING</div>
      <div className="access-control-permission short">
        {props.permission.short}
      </div>
      <div className="access-control-permission description">
        {description}
      </div>
    </div>
  );
}

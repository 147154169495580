import _curry from "lodash/fp/curry.js";
import _merge from "lodash/fp/merge.js";
import jtgq from "json-to-graphql-query";

import {addTypeName} from "./add_type_name.js";
import {postGraphQL} from "./post_graphql.js";
import {deserialize} from "./deserialize.js";
import {serialize} from "./serialize.js";

export const mutation = _curry((metadata, session, action, properties, data) => {
  const allProperties = addTypeName(_merge(metadata.properties.default, properties));
  const mutationName = action + metadata.typeName;
  let mutation = {mutation: {[mutationName]: {
    __args: {input: serialize(data)}, ...allProperties
  }}};
  return postGraphQL(session, jtgq.jsonToGraphQLQuery(mutation), {})
  .then((p) => deserialize(p.data[mutationName]));
});

export function createChangeSet(inAnySelected, inAllSelected, additions, removals, clickedId) {
  let changedAdditions = new Set(additions);
  let changedRemovals = new Set(removals);
  if (removals.has(clickedId)) {
    changedAdditions.delete(clickedId);
    changedRemovals.delete(clickedId);
  } else if (inAllSelected.find(s => s.id === clickedId)) {
    if (additions.has(clickedId)) {
      changedAdditions.delete(clickedId);
      changedRemovals.add(clickedId);
    } else {
      changedAdditions.delete(clickedId);
      changedRemovals.add(clickedId);
    }
  } else if (inAnySelected.find(s => s.id === clickedId)) {
    if (additions.has(clickedId)) {
      changedAdditions.delete(clickedId);
      changedRemovals.add(clickedId);
    } else {
      changedAdditions.add(clickedId);
      changedRemovals.delete(clickedId);
    }
  } else {
    if (additions.has(clickedId)) {
      changedAdditions.delete(clickedId);
      changedRemovals.delete(clickedId);
    } else {
      changedAdditions.add(clickedId);
      changedRemovals.delete(clickedId);
    }
  }

  return [changedAdditions, changedRemovals];
}

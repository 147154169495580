import _ from "lodash";
import {createSelector} from "@reduxjs/toolkit";

import {flatSelector} from "../utility/flat_selector.js";
import {regexFromUser} from "../utility/regex_from_user.js";
import {selectStoredFilters} from "./select_stored_filters.js";

export function selectPermissions(intl) {
  const selectTranslatedPermission = createSelector(
    flatSelector("permissions"), ps => {
      return ps.map(p => {
        let k = _.compact([
          p.action,
          p.target,
          p.targetType,
          p.targetCreatedBy,
          p.targetOrganization
        ]).join(".");
        if (p.targetIdentifier) {
          k += ".identified";
        }
        return Object.assign(
          {}, p, {short: intl.formatMessage(
            {id: "accessControl.permission.short." + k},
            {identifier: _.lowerCase(p.targetIdentifier)}
          )}
        );
      });
    }
  );

  const selectFilteredPermissions = createSelector(
    selectTranslatedPermission, selectStoredFilters, (tp, sf) => {
      const filterExp = regexFromUser(sf.permissions);
      return tp.filter(p => {
        return filterExp.test(p.short)
          || filterExp.test(p.description)
          || filterExp.test(p.id);
      });
    }
  );

  return createSelector(selectFilteredPermissions, p => _.orderBy(p, "short", "asc"));
}

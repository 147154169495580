import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

import {CONFIRM_DESTRUCTIVE_TIMEOUT} from "../constants.js";

export function ArchiveButton(props) {
  const intl = useIntl();

  const [confirming, setConfirming] = useState(false);
  const session = useSelector(s => s.accessControlSession);

  const disabled = props.disabled || props.reservation.archivedAt;

  const toggleConfirming = () => {
    setConfirming(true);
    props.setConfirming?.(true);
    setTimeout(() => {
      setConfirming(false);
      props.setConfirming?.(false);
    },CONFIRM_DESTRUCTIVE_TIMEOUT);
  };

  const toggleArchived = async () => {
    const archivedAt = (props.reservation.archivedAt) ? null : Date.now();
    await Aqumen.Reservation.update(session, {id: props.reservation.id}, {archivedAt});
    await props.reload();
    props.setConfirming?.(false);
    setConfirming(false);
  };

  let messageKey;
  if (props.reservation.archivedAt) {
    messageKey = "archived";
  } else {
    messageKey = (props.reservation.rejectedAt) ? "archive" : "cancel";
  }

  if (!confirming) {
    return (
      <div className="account-reservation-archive">
        <button className="account-reservation-archive-button" onClick={toggleConfirming} disabled={disabled}>
          {intl.formatMessage({id: `account.reservations.reservation.${messageKey}.button`})}
        </button>
      </div>
    );
  }

  if (confirming) {
    return (
      <div className="account-reservation-archive">
        <button className="account-reservation-archive-button confirming" onClick={toggleArchived} disabled={disabled}>
          {intl.formatMessage({id: `account.reservations.reservation.${messageKey}.confirm.button`})}
        </button>
      </div>
    );
  }
}

import {deserialize} from "./deserialize.js";
import {fetch} from "./fetch.js";
import {orderBy} from "./order_by.js";
import {query} from "./query.js";
import {mutation} from "./mutation.js";
import {postGraphQL} from "./post_graphql.js";
import {serialize} from "./serialize.js";
import {websocket} from "./websocket.js";

export default {
  deserialize, fetch, orderBy, query, mutation, postGraphQL, serialize, websocket
};

import {agreementsRequest} from "./agreements_request.js";
import {compilersRequest} from "./compilers_request.js";
import {controlServersRequest} from "./control_servers_request.js";
import {jobQueuesRequest} from "./job_queues_request.js";
import {jobsRequest} from "./jobs_request.js";
import {metricsRequest} from "./metrics_request.js";
import {organizationsRequest} from "./organizations_request.js";
import {permissionsRequest} from "./permissions_request.js";
import {reloadOne} from "./reload_one.js";
import {reservationsRequest} from "./reservations_request.js";
import {rolesRequest} from "./roles_request.js";
import {simulatorsRequest} from "./simulators_request.js";
import {usersRequest} from "./users_request.js";

export function reloadAll(dispatch, sessions) {
  reloadOne("Agreement", agreementsRequest, dispatch, sessions);
  reloadOne("Compiler", compilersRequest, dispatch, sessions);
  reloadOne("ControlServer", controlServersRequest, dispatch, sessions);
  reloadOne("Job", jobsRequest, dispatch, sessions);
  reloadOne("Metric", metricsRequest, dispatch, sessions);
  reloadOne("Organization", organizationsRequest, dispatch, sessions);
  reloadOne("Permission", permissionsRequest, dispatch, sessions);
  reloadOne("Queue", jobQueuesRequest, dispatch, sessions);
  reloadOne("Reservation", reservationsRequest, dispatch, sessions);
  reloadOne("Role", rolesRequest, dispatch, sessions);
  reloadOne("Simulator", simulatorsRequest, dispatch, sessions);
  reloadOne("User", usersRequest, dispatch, sessions);
}

import React from "react";
import {useIntl} from "react-intl"
import {useDispatch, useSelector} from "react-redux";

import {NavigationButtonSignOut} from "../navigation/navigation_button_sign_out.jsx";
import {reloadCurrentUser} from "../requests/reload_current_user.js";

export function NoEnvironmentAccessView(props) {
  console.group("NoEnvironmentAccessView");
  const dispatch = useDispatch();
  const intl = useIntl();
  const accessControlSession = useSelector(s => s.accessControlSession);

  const handleReloadPermissions = () => {
    return reloadCurrentUser(dispatch, accessControlSession);
  };

  try {
    return (
      <div className={"no-environment-access-view authentication-view view" + (props.className ?? "")}>

        <h1 className="title-no-access title">
          {intl.formatMessage({id: "noAccess.title"}, {elevated: (copy) => (<span className="elevated">{copy}</span>)})}
        </h1>

        <hr className="help-divider"/>

        <p className="help-no-access">{intl.formatMessage({id: "noAccess.help"})}</p>

        <hr className="help-divider"/>

        <NavigationButtonSignOut/>
        <button className="button reload" onClick={handleReloadPermissions}>
          {intl.formatMessage({id: "noAccess.reload"})}
        </button>
      </div>
    );
  } finally {
    console.groupEnd();
  }
}
